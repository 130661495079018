import { useAirModal } from '@air/provider-modal';
import Router from 'next/router';
import { useCallback } from 'react';

import { CopyToWorkspaceModal, CopyToWorkspaceModalProps } from '~/components/CopyToWorkspace/CopyToWorkspaceModal';
import { SignupModal } from '~/components/Modals/SignupModal/SignUpModal';
import { SSOCallbackData } from '~/components/PublicSSOCallback/utils';
import { useAccountContext } from '~/providers/AccountProvider';

export const useCopyToWorkspace = () => {
  const { data: account } = useAccountContext();
  const [showSignUpModal] = useAirModal(SignupModal);
  const [showCopyToWorkspaceModal] = useAirModal(CopyToWorkspaceModal);

  const copyToAir = useCallback(
    (props: CopyToWorkspaceModalProps) => {
      Router.ready(() => {
        const ssoCallbackData: SSOCallbackData = {
          url: Router.asPath,
          action: {
            action: 'copy-to-air',
            data: props,
          },
        };

        if (!account || account.type === 'anonymous') {
          showSignUpModal({
            ssoCallbackData,
            onSuccess: () => showCopyToWorkspaceModal(props),
            accountCreationSource: 'share-link',
          });
        } else {
          showCopyToWorkspaceModal(props);
        }
      });
    },
    [account, showCopyToWorkspaceModal, showSignUpModal],
  );

  return {
    copyToAir,
  };
};
