import { ComponentProps, forwardRef, memo } from 'react';

import { KANBAN_COL_GUTTER } from '~/components/KanbanView/shared/kanbanConstants';

type KanbanColumnSpacingWrapperProps = ComponentProps<'div'>;

/** This component puts left/right margins on the column consistently, so that columns
 * appear the same in react-window as well as null state
 */
const _KanbanColumnSpacingWrapper = forwardRef<HTMLDivElement, KanbanColumnSpacingWrapperProps>(
  ({ style, ...props }: KanbanColumnSpacingWrapperProps, ref) => (
    <div
      ref={ref}
      style={{ paddingLeft: KANBAN_COL_GUTTER / 2, paddingRight: KANBAN_COL_GUTTER / 2, ...style }}
      {...props}
    />
  ),
);
_KanbanColumnSpacingWrapper.displayName = '_KanbanColumnSpacingWrapper';

export const KanbanColumnSpacingWrapper = memo(_KanbanColumnSpacingWrapper) as typeof _KanbanColumnSpacingWrapper;

KanbanColumnSpacingWrapper.displayName = 'KanbanColumnSpacingWrapper';
