import { PublicClip } from '@air/api';
import Router from 'next/router';
import { memo, useCallback } from 'react';
import isEqual from 'react-fast-compare';

import { useGetPublicAssetMenuOptionsCallback } from '~/components/PublicBoard/useGetPublicAssetMenuOptionsCallback';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { QueryParamNames } from '~/constants/search';
import { useGoToAssetPage } from '~/hooks/useGoToAssetPage';
import { usePublicSelectionMenuOptions } from '~/hooks/usePublicSelectionMenuOptions';
import { AssetModalPanel } from '~/providers/AssetModalPanelContextProvider';
import { usePublicCustomFieldsContext } from '~/providers/PublicCustomFieldsProvider';
import {
  canChangeAssetCustomFields,
  canDownloadVersion,
  canSeeAssetCustomFields,
  canSeeAssetVersions,
  canViewAssetDiscussions,
} from '~/utils/permissions/assetPermissions';
import { isMobileAgent } from '~/utils/PlatformHelpers';

import { KanbanFileComponent } from './shared/KanbanFileComponent';
import { KanbanFileProps } from './shared/kanbanItemTypes';

export const PublicKanbanFile = memo(({ cardHeight, file, kanbanDndItemData, style }: KanbanFileProps<PublicClip>) => {
  const { permissions } = usePublicPermissions();
  const { customFields } = usePublicCustomFieldsContext();
  const { getSelectionMenuOptions } = usePublicSelectionMenuOptions();
  const { getAssetMenuOptionsCallback } = useGetPublicAssetMenuOptionsCallback();
  const { goToAssetPage } = useGoToAssetPage();
  const _getItemMenuOptions = getAssetMenuOptionsCallback({ item: file });
  const onItemClick = useCallback(() => {
    Router.ready(() => {
      goToAssetPage({
        asset: file,
        trackLocation: 'card-click',
      });
    });
  }, [file, goToAssetPage]);

  const onCustomFieldsClick = useCallback(() => {
    Router.ready(() => {
      goToAssetPage({
        asset: file,
        query: {
          [QueryParamNames.tab]: AssetModalPanel.FIELDS,
        },
        trackLocation: 'custom-field-click',
      });
    });
  }, [file, goToAssetPage]);

  const onDiscussionsClick = useCallback(
    () =>
      goToAssetPage({
        asset: file,
        query: {
          [QueryParamNames.tab]: AssetModalPanel.COMMENTS,
        },
        trackLocation: 'comments-click',
      }),
    [file, goToAssetPage],
  );

  const onVersionsClick = useCallback(
    () =>
      goToAssetPage({
        asset: file,
        query: {
          [QueryParamNames.versions]: true,
        },
        trackLocation: 'versions-click',
      }),
    [file, goToAssetPage],
  );

  const canDownload = canDownloadVersion(permissions);
  const canEditCustomFields = canChangeAssetCustomFields(permissions);
  const canViewCustomFields = canSeeAssetCustomFields(permissions);
  const canViewAssetVersions = canSeeAssetVersions(permissions);
  const canViewDiscussions = canViewAssetDiscussions(permissions);
  const canChangeCustomFields = canChangeAssetCustomFields(permissions);

  const isSelectable = canDownload || canEditCustomFields;

  return (
    <KanbanFileComponent
      cardHeight={cardHeight}
      dndDisabled={isMobileAgent || !canChangeCustomFields}
      file={file}
      getItemMenuOptions={_getItemMenuOptions}
      getSelectionMenuOptions={getSelectionMenuOptions}
      isSelectable={isSelectable}
      kanbanDndItemData={kanbanDndItemData}
      onCustomFieldsClick={onCustomFieldsClick}
      onFileClick={onItemClick}
      style={style}
      canViewCustomFields={canViewCustomFields}
      allCustomFields={customFields}
      onDiscussionsClick={canViewDiscussions ? onDiscussionsClick : undefined}
      onVersionsClick={
        canViewAssetVersions && !!file.assetVersionCount && file.assetVersionCount > 1 ? onVersionsClick : undefined
      }
    />
  );
}, isEqual);

PublicKanbanFile.displayName = 'PublicKanbanFile';
