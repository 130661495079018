import { PublicClip } from '@air/api';
import Router from 'next/router';
import { memo, useCallback } from 'react';
import isEqual from 'react-fast-compare';

import { useGetPublicAssetMenuOptionsCallback } from '~/components/PublicBoard/useGetPublicAssetMenuOptionsCallback';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { QueryParamNames } from '~/constants/search';
import { useGoToAssetPage } from '~/hooks/useGoToAssetPage';
import { usePublicSelectionMenuOptions } from '~/hooks/usePublicSelectionMenuOptions';
import { AssetModalPanel } from '~/providers/AssetModalPanelContextProvider';
import { usePublicCustomFieldsContext } from '~/providers/PublicCustomFieldsProvider';
import {
  canChangeAssetCustomFields,
  canDownloadVersion,
  canSeeAssetCustomFields,
  canSeeAssetVersions,
  canViewAssetDiscussions,
} from '~/utils/permissions/assetPermissions';
import { isMobileAgent } from '~/utils/PlatformHelpers';

import { KanbanAssetComponent } from './shared/KanbanAssetComponent';
import { KanbanAssetProps } from './shared/kanbanItemTypes';

export const PublicKanbanAsset = memo(
  ({ cardHeight, asset, kanbanDndItemData, style }: KanbanAssetProps<PublicClip>) => {
    const { permissions } = usePublicPermissions();
    const { customFields } = usePublicCustomFieldsContext();
    const { getSelectionMenuOptions } = usePublicSelectionMenuOptions();
    const { getAssetMenuOptionsCallback } = useGetPublicAssetMenuOptionsCallback();
    const { goToAssetPage } = useGoToAssetPage();
    const _getItemMenuOptions = getAssetMenuOptionsCallback({ item: asset });

    const onItemClick = useCallback(() => {
      Router.ready(() => {
        goToAssetPage({
          asset,
          trackLocation: 'card-click',
        });
      });
    }, [asset, goToAssetPage]);

    const onCustomFieldsClick = useCallback(() => {
      Router.ready(() => {
        goToAssetPage({
          asset,
          query: {
            [QueryParamNames.tab]: AssetModalPanel.FIELDS,
          },
          trackLocation: 'custom-field-click',
        });
      });
    }, [asset, goToAssetPage]);

    const onDiscussionsClick = useCallback(
      () =>
        goToAssetPage({
          asset,
          query: {
            [QueryParamNames.tab]: AssetModalPanel.COMMENTS,
          },
          trackLocation: 'comments-click',
        }),
      [asset, goToAssetPage],
    );

    const onVersionsClick = useCallback(
      () =>
        goToAssetPage({
          asset,
          query: {
            [QueryParamNames.versions]: true,
          },
          trackLocation: 'versions-click',
        }),
      [asset, goToAssetPage],
    );

    const canDownload = canDownloadVersion(permissions);
    const canViewCustomFields = canSeeAssetCustomFields(permissions);
    const canEditCustomFields = canChangeAssetCustomFields(permissions);
    const canViewAssetVersions = canSeeAssetVersions(permissions);
    const canViewDiscussions = canViewAssetDiscussions(permissions);

    const isSelectable = canDownload || canEditCustomFields;

    return (
      <KanbanAssetComponent
        cardHeight={cardHeight}
        clip={asset}
        dndDisabled={isMobileAgent || !canEditCustomFields}
        getItemMenuOptions={_getItemMenuOptions}
        getSelectionMenuOptions={getSelectionMenuOptions}
        isSelectable={isSelectable}
        kanbanDndItemData={kanbanDndItemData}
        onAssetClick={onItemClick}
        onCustomFieldsClick={onCustomFieldsClick}
        onDiscussionsClick={onDiscussionsClick}
        onVersionsClick={onVersionsClick}
        style={style}
        canViewCustomFields={canViewCustomFields}
        allCustomFields={customFields}
        shouldShowDiscussions={canViewDiscussions}
        showVersionsIndicator={canViewAssetVersions && !!asset.assetVersionCount && asset.assetVersionCount > 1}
      />
    );
  },
  isEqual,
);

PublicKanbanAsset.displayName = 'PublicKanbanAsset';
