import { AnimateLayoutChanges, defaultAnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { CSSProperties, memo, ReactNode, useMemo } from 'react';

import {
  KANBAN_CARD_LEFT_OFFSET,
  KANBAN_ITEM_CARD_PADDING,
  KANBAN_ITEM_CARD_WIDTH,
  KANBAN_ITEM_DROP_LINE_HEIGHT,
  KANBAN_ITEM_DROP_PLACEHOLDER_HEIGHT,
  KANBAN_ITEM_GUTTER,
} from '~/components/KanbanView/shared/kanbanConstants';
import { kanbanColumnItemsSelector } from '~/store/kanbanManager/selectors';
import { DndSortableKanbanItemData, isDndDroppableKanbanColumnData, isDndSortableKanbanItemData } from '~/types/DndKit';
import { useAirStore } from '~/utils/ReduxUtils';

interface DndSortableKanbanItemProps {
  id: string;
  children: ReactNode;
  data: DndSortableKanbanItemData;
  dndDisabled?: boolean;
  style?: CSSProperties;
}
// from sortable multicontainer example
const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  args.isSorting || args.wasDragging ? defaultAnimateLayoutChanges(args) : true;

/**
 * This component is aware of its place in a react-window virtualized list
 * It is also taking on the sorting for kanban items
 *
 * This component puts left/right margins on the Item consistently, so that Items
 * appear the same in react-window as well as null state
 * gutter is only on top because we want the shadow to appear at the top of scroll position
 */
export const DndSortableKanbanItem = memo(({ id, children, data, dndDisabled, style }: DndSortableKanbanItemProps) => {
  const { getState } = useAirStore();

  const { isDragging, isSorting, attributes, listeners, setNodeRef, transform, transition, active, over } = useSortable(
    {
      id,
      data,
      disabled: dndDisabled,
      animateLayoutChanges,
    },
  );

  const showPlaceholderLine: 'above' | 'below' | false = useMemo(() => {
    const activeData = active?.data.current;
    const overData = over?.data.current;

    // if this item is the active item, don't show above or below
    if (
      !isDndSortableKanbanItemData(activeData) ||
      !overData ||
      data.itemId === activeData.itemId ||
      isDndDroppableKanbanColumnData(overData)
    ) {
      return false;
    }

    // if over is kanban column and i am the last item in the column, show the line after
    if (isDndDroppableKanbanColumnData(overData) && data.currentKanbanColumnId === overData.kanbanColumnId) {
      const items = kanbanColumnItemsSelector(getState(), overData.kanbanColumnId);
      const lastItem = items[items.length - 1];
      if (lastItem.itemId === data.itemId) return 'below';
    }

    // if this item is the overItem, show above?
    if ('itemId' in overData && data.itemId === overData.itemId) {
      // if active is in the column and active is above me, put the line after me
      if (activeData.currentKanbanColumnId === data.currentKanbanColumnId) {
        const items = kanbanColumnItemsSelector(getState(), activeData.currentKanbanColumnId);
        const indexOfActive = items.findIndex((i) => i.itemId === activeData.itemId);
        const indexOfItem = items.findIndex((i) => i.itemId === data.itemId);
        if (indexOfActive !== -1 && indexOfItem !== -1 && indexOfActive < indexOfItem) return 'below';
      }
      return 'above';
    }
    return false;
  }, [getState, active, over, data]);

  const memoizedStyle = useMemo<CSSProperties>(
    () => ({
      ...style,
      transform: isSorting ? undefined : CSS.Translate.toString(transform),
      transition,
      left: KANBAN_CARD_LEFT_OFFSET,
      width: KANBAN_ITEM_CARD_WIDTH,
      marginTop: showPlaceholderLine === 'above' ? KANBAN_ITEM_GUTTER - KANBAN_ITEM_CARD_PADDING : KANBAN_ITEM_GUTTER,
    }),
    [showPlaceholderLine, style, transform, transition, isSorting],
  );
  const placeholderLine = useMemo(
    () => (
      <div
        className="flex items-start justify-center"
        style={{
          height: KANBAN_ITEM_DROP_PLACEHOLDER_HEIGHT,
          marginBottom: showPlaceholderLine === 'above' ? KANBAN_ITEM_GUTTER - KANBAN_ITEM_CARD_PADDING : undefined,
          marginTop: showPlaceholderLine === 'below' ? KANBAN_ITEM_GUTTER + KANBAN_ITEM_CARD_PADDING : undefined,
        }}
      >
        <div className="w-full rounded-sm bg-blue-9" style={{ height: KANBAN_ITEM_DROP_LINE_HEIGHT }} />
      </div>
    ),
    [showPlaceholderLine],
  );
  return (
    <div data-testid={data.itemId} ref={setNodeRef} style={memoizedStyle} {...attributes} {...listeners}>
      {showPlaceholderLine === 'above' && placeholderLine}
      {children}
      {showPlaceholderLine === 'below' && placeholderLine}
      {isDragging && (
        <div
          className="absolute bg-grey-1 opacity-80"
          style={{
            top: -KANBAN_ITEM_CARD_PADDING,
            left: -KANBAN_ITEM_CARD_PADDING,
            right: -KANBAN_ITEM_CARD_PADDING,
            bottom: KANBAN_ITEM_CARD_PADDING + KANBAN_ITEM_GUTTER,
          }}
        />
      )}
    </div>
  );
});

DndSortableKanbanItem.displayName = 'DndSortableKanbanItem';
