import { type BoardCustomFieldValue } from '@air/api/types';
import { Tooltip } from '@air/primitive-tooltip';
import { memo } from 'react';

import { CustomField } from '~/components/CustomField';
import { PAGE_CONTENT_HEADER_MAX_WIDTH } from '~/components/PageHeader/constants';
import { BOARD_BLURB_CUSTOM_FIELDS } from '~/constants/testIDs';
import { getFlattenedCustomFieldValues } from '~/utils/CustomFields';

interface BoardBlurbCustomFieldsProps {
  onPanelClick?: () => void;
  customFields?: BoardCustomFieldValue[];
}

export const BoardBlurbCustomFields = memo(({ onPanelClick, customFields }: BoardBlurbCustomFieldsProps) => {
  if (!customFields?.length) return null;

  const fieldsToRender = getFlattenedCustomFieldValues({ customFields });

  if (!fieldsToRender.length) {
    return null;
  }

  return (
    <div
      data-testid={BOARD_BLURB_CUSTOM_FIELDS}
      className="flex flex-wrap gap-2"
      style={{
        maxWidth: PAGE_CONTENT_HEADER_MAX_WIDTH,
      }}
    >
      {fieldsToRender.map((field) => (
        <Tooltip label={field.fieldName} key={field.id}>
          <CustomField
            appearance="rectangular"
            className={!!onPanelClick ? 'cursor-pointer' : ''}
            field={field}
            onClick={onPanelClick}
            size="medium"
          />
        </Tooltip>
      ))}
    </div>
  );
});

BoardBlurbCustomFields.displayName = 'BoardBlurbCustomFields';
