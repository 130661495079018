import { Permissions } from '@air/api';
import { Clip, ClipAndBoardListItem } from '@air/api/types';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import produce from 'immer';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { useKanbanFetchPageContext } from '~/components/KanbanView/KanbanColumnsManagers/shared/KanbanFetchPageContext';
import { useKanbanContext } from '~/components/KanbanView/Providers/KanbanProvider';
import { useAssetPermissionsCache } from '~/hooks/useAssetPermissionsCache';
import { useBoardPermissionsCache } from '~/hooks/useBoardPermissionsCache';
import { useClipPermissionsCache } from '~/hooks/useClipPermissionsCache';
import { useRegisterClipEvents } from '~/hooks/useRegisterClipEvents';
import { useURLShortIdSelector } from '~/hooks/useURLShortIdSelector';
import { kanbanColumnFetcherSyncedAction, updateKanbanColumnWithNewDataAction } from '~/store/kanbanManager/actions';
import { kanbanColumnUnsyncedItemsSelector } from '~/store/kanbanManager/selectors';
import { KanbanColumnApiData } from '~/types/KanbanData';
import { useAirSelector } from '~/utils/ReduxUtils';

import { combinePaginatedColumnData } from './shared/combinePaginatedColumnData';
import { useGetKanbanColumnItems } from './useGetKanbanColumnItems';

interface KanbanColumnFetchSync {
  kanbanColumnId: string;
}
export const KanbanColumnFetchSync = memo(({ kanbanColumnId }: KanbanColumnFetchSync) => {
  const dispatch = useDispatch();
  const unsyncedItems = useAirSelector((state) => kanbanColumnUnsyncedItemsSelector(state, kanbanColumnId));

  const { setFetchNextPage, setIsFetchingNextPage, setHasNextPage } = useKanbanFetchPageContext();

  const { queryKey, data, hasNextPage, isFetchingNextPage, fetchNextPage } = useGetKanbanColumnItems({
    kanbanColumnId,
  });

  useEffect(() => {
    setFetchNextPage(fetchNextPage);
  }, [fetchNextPage, setFetchNextPage]);

  useEffect(() => {
    setIsFetchingNextPage(isFetchingNextPage);
  }, [isFetchingNextPage, setIsFetchingNextPage]);

  useEffect(() => {
    setHasNextPage(hasNextPage);
  }, [hasNextPage, setHasNextPage]);

  const queryClient = useQueryClient();
  const { setBoardPermissions } = useBoardPermissionsCache();
  const { setClipPermissions } = useClipPermissionsCache();
  const { setAssetPermissions } = useAssetPermissionsCache();
  const { workspaceId } = useKanbanContext();
  // This should not be used here, shortId should be passed in as a prop
  // But this is a deeply nested component, so it's not easy to pass it down
  // We should refactor this to use a context
  const shortId = useURLShortIdSelector();

  const isMutatingRef = useRef(false);

  const updateQueryData = useCallback(
    async (unsyncedItems: { data: ClipAndBoardListItem[]; total: number }) => {
      if (isMutatingRef.current) return;
      isMutatingRef.current = true;
      queryClient.setQueryData<InfiniteData<KanbanColumnApiData> | undefined>(queryKey, (data) => {
        if (!data) return;

        return produce(data, (draft) => {
          draft.pages[0].data = [...unsyncedItems.data];
          draft.pages[0].total = unsyncedItems.total;
        });
      });
      dispatch(kanbanColumnFetcherSyncedAction({ kanbanColumnId }));
      isMutatingRef.current = false;
    },
    [queryClient, queryKey, dispatch, kanbanColumnId],
  );

  const combinedData = useMemo(() => combinePaginatedColumnData(data?.pages || []), [data?.pages]);

  //@ts-ignore why don't we have a type for this?
  const clips: Clip[] | undefined = useMemo(
    //@ts-ignore why don't we have a type for this?
    () => combinedData?.filter((item) => item.type !== 'board').map((item) => item.data),
    [combinedData],
  );

  useRegisterClipEvents({
    clips: clips || [],
    workspaceId,
  });

  const updateKanbanStore = useCallback(async () => {
    if (combinedData) {
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      //@ts-ignore why don't we have a type for this?
      const boardIds = combinedData.filter((item) => item.type === 'board').map((item) => item.data.id);
      //@ts-ignore why don't we have a type for this?
      const clipIds = combinedData.filter((item) => item.type !== 'board').map((item) => item.data.id);

      /**
       * We need to fetch permissions for assets because we use their permissions for certain endpoints
       * @see https://air-labs-team.slack.com/archives/C052RDYCGAG/p1695838522133639
       */
      //@ts-ignore why don't we have a type for this?
      const assetIds = combinedData.filter((item) => item.type !== 'board').map((item) => item.data.assetId);

      if (boardIds.length || clipIds.length || assetIds.length) {
        Permissions.getFromObjects({
          ...(shortId ? { shortId } : { workspaceId }),
          objects: {
            boardIds,
            clipIds,
            assetIds,
          },
          mode: 'all',
        }).then((res) => {
          boardIds.forEach((boardId) => setBoardPermissions({ boardId, permissions: res[boardId] }));
          clipIds.forEach((clipId) => setClipPermissions({ clipId, permissions: res[clipId] }));
          assetIds.forEach((assetId) => setAssetPermissions({ assetId, permissions: res[assetId] }));
        });
      }
    }

    dispatch(
      updateKanbanColumnWithNewDataAction({
        shouldSkipSWRSync: true,
        columnsToUpdate: { [kanbanColumnId]: { data: combinedData || [], total: data?.pages[0]?.total || 0 } },
      }),
    );
  }, [
    combinedData,
    dispatch,
    kanbanColumnId,
    data?.pages,
    workspaceId,
    shortId,
    setBoardPermissions,
    setClipPermissions,
    setAssetPermissions,
  ]);

  useEffect(() => {
    updateKanbanStore();
  }, [updateKanbanStore]);

  useEffect(() => {
    if (unsyncedItems) {
      updateQueryData(unsyncedItems);
    }
  }, [updateQueryData, unsyncedItems]);

  return null;
});

KanbanColumnFetchSync.displayName = 'KanbanColumnFetchSync';
