import { memo } from 'react';

import { KanbanFakeGroup } from '~/components/KanbanFakeGroup';

import { KanbanColumnContainer } from './shared/KanbanColumnContainer';

export const KanbanLoadingState = memo(() => {
  return (
    <div className="h-full">
      <KanbanFakeGroup>
        <KanbanColumnContainer isLoading />
        <KanbanColumnContainer isLoading />
        <KanbanColumnContainer isLoading />
        <KanbanColumnContainer isLoading />
        <KanbanColumnContainer isLoading />
      </KanbanFakeGroup>
    </div>
  );
});

KanbanLoadingState.displayName = 'KanbanLoadingState';
