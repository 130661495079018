import { Spinner } from '@air/primitive-spinner';

import { InfiniteScrollContainer } from './InfiniteScrollContainer';

interface InfiniteScrollSpinnerProps {
  dataTestId?: string;
}

export const InfiniteScrollSpinner = ({ dataTestId }: InfiniteScrollSpinnerProps) => {
  return (
    <InfiniteScrollContainer>
      <Spinner className="h-7 w-7 text-teal-9" data-testid={dataTestId} />
    </InfiniteScrollContainer>
  );
};
