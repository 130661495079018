import { Clip } from '@air/api/types';
import { memo, useCallback } from 'react';
import isEqual from 'react-fast-compare';

import { useGetPrivateAssetMenuOptionsCallback } from '~/components/PrivateGallery/useGetPrivateAssetMenuOptionsCallback';
import { QueryParamNames } from '~/constants/search';
import { useClipPermissions } from '~/hooks/useClipPermissions';
import { useGoToAssetPage } from '~/hooks/useGoToAssetPage';
import { useOpenAssetInNewTab } from '~/hooks/useOpenAssetInNewTab';
import { usePrivateSelectionMenuOptions } from '~/hooks/usePrivateSelectionMenuOptions';
import { AssetModalPanel } from '~/providers/AssetModalPanelContextProvider';
import { usePrivateCustomFieldsContext } from '~/providers/PrivateCustomFieldsProvider';
import {
  canChangeAssetCustomFields,
  canSeeAssetCustomFields,
  canViewAssetDiscussions,
} from '~/utils/permissions/assetPermissions';
import { isMobileAgent } from '~/utils/PlatformHelpers';

import { KanbanAssetComponent } from './shared/KanbanAssetComponent';
import { KanbanAssetProps } from './shared/kanbanItemTypes';

export const PrivateKanbanAsset = memo(({ cardHeight, asset, kanbanDndItemData, style }: KanbanAssetProps<Clip>) => {
  const { clipPermissions } = useClipPermissions({
    clipId: asset.id,
  });

  const { customFields } = usePrivateCustomFieldsContext();
  const { getSelectionMenuOptions } = usePrivateSelectionMenuOptions();
  const { getAssetMenuOptionsCallback } = useGetPrivateAssetMenuOptionsCallback();
  const _getItemMenuOptions = getAssetMenuOptionsCallback({ item: asset });
  const { goToAssetPage } = useGoToAssetPage();
  const { openAssetInNewTab } = useOpenAssetInNewTab();

  const onCustomFieldsClick = useCallback(
    () =>
      goToAssetPage({
        asset,
        query: {
          [QueryParamNames.tab]: AssetModalPanel.FIELDS,
        },
        trackLocation: 'custom-field-click',
      }),
    [asset, goToAssetPage],
  );

  const onDiscussionsClick = useCallback(
    () =>
      goToAssetPage({
        asset,
        query: {
          [QueryParamNames.tab]: AssetModalPanel.COMMENTS,
        },
        trackLocation: 'comments-click',
      }),
    [asset, goToAssetPage],
  );

  const onVersionsClick = useCallback(
    () =>
      goToAssetPage({
        asset,
        query: {
          [QueryParamNames.versions]: true,
        },
        trackLocation: 'versions-click',
      }),
    [asset, goToAssetPage],
  );

  const onItemClick = useCallback(
    () =>
      goToAssetPage({
        asset,
        trackLocation: 'card-click',
      }),
    [asset, goToAssetPage],
  );
  const onItemCmdClick = useCallback(
    () =>
      openAssetInNewTab({
        asset,
        trackLocation: 'card-click',
      }),
    [asset, openAssetInNewTab],
  );

  const canViewCustomFields = canSeeAssetCustomFields(clipPermissions);
  const canSeeDiscussions = canViewAssetDiscussions(clipPermissions);
  const canChangeCustomFields = canChangeAssetCustomFields(clipPermissions);

  return (
    <KanbanAssetComponent
      cardHeight={cardHeight}
      clip={asset}
      getItemMenuOptions={_getItemMenuOptions}
      getSelectionMenuOptions={getSelectionMenuOptions}
      isSelectable={false}
      kanbanDndItemData={kanbanDndItemData}
      onAssetClick={onItemClick}
      onAssetTitleCmdClick={onItemCmdClick}
      onCustomFieldsClick={onCustomFieldsClick}
      onDiscussionsClick={onDiscussionsClick}
      onVersionsClick={onVersionsClick}
      style={style}
      dndDisabled={isMobileAgent || !canChangeCustomFields}
      canViewCustomFields={canViewCustomFields}
      allCustomFields={customFields}
      shouldShowDiscussions={canSeeDiscussions}
      showVersionsIndicator={!!asset.assetVersionCount && asset.assetVersionCount > 1}
    />
  );
}, isEqual);

PrivateKanbanAsset.displayName = 'PrivateKanbanAsset';
