import { EditableText } from '@air/zephyr-editable-text';
import { memo } from 'react';

import Linkify from '~/components/Linkify';

export interface EditableHeaderDescriptionProps {
  onSubmit?: (newValue: string) => void;
  text: string | undefined;
  testId?: string;
  label: string;
}

export const EditableContentHeaderDescription = memo(
  ({ label, onSubmit, text, testId }: EditableHeaderDescriptionProps) => {
    if (onSubmit) {
      return (
        <EditableText
          formatValue={(value) => <Linkify>{value}</Linkify>}
          label={label}
          onSubmit={(values) => {
            const value = values['editable-text-value'].trim();

            if (value !== text) {
              onSubmit(value);
            }
          }}
          data-testid={testId}
          placeholder="Click to add a description"
          tx={{
            ml: -4,
            wordBreak: 'break-word',

            EditableTextButton: {
              color: 'var(--colors-12)',
              display: 'inline-block',

              '&.focus-visible': {
                boxShadow: 'none !important',
              },
            },
          }}
          value={text ?? ''}
          variant="text-ui-14"
        />
      );
    }

    return (
      <Linkify>
        <p
          data-testid={testId}
          data-disableselect="true"
          className="whitespace-pre-wrap break-words text-14 text-grey-12"
        >
          {text}
        </p>
      </Linkify>
    );
  },
);

EditableContentHeaderDescription.displayName = 'EditableHeaderDescription';
