import { type BoardCustomFieldValue, CustomFieldTypeName } from '@air/api/types';
import { Skeleton } from '@air/component-skeleton';
import { ComponentType, memo } from 'react';

interface CustomFieldsPanelContentProps {
  fields: BoardCustomFieldValue[] | undefined;
  SelectCustomField: ComponentType<{ field: BoardCustomFieldValue }>;
  PlainTextCustomField: ComponentType<{ field: BoardCustomFieldValue }>;
  DateCustomField: ComponentType<{ field: BoardCustomFieldValue }>;
}

export const CustomFieldsPanelContent = memo(
  ({ fields, SelectCustomField, PlainTextCustomField, DateCustomField }: CustomFieldsPanelContentProps) => {
    return (
      <>
        {!!fields ? (
          fields.map((field) => {
            switch (field.type.title) {
              case CustomFieldTypeName.singleSelect:
              case CustomFieldTypeName.multiSelect: {
                return <SelectCustomField field={field} key={field.id} />;
              }
              case CustomFieldTypeName.plainText: {
                return <PlainTextCustomField field={field} key={field.id} />;
              }
              case CustomFieldTypeName.date:
                return <DateCustomField field={field} key={field.id} />;
            }
          })
        ) : (
          <Skeleton height={20} width={200} />
        )}
      </>
    );
  },
);

CustomFieldsPanelContent.displayName = 'CustomFieldsPanelContent';
