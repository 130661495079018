import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import { getInitialKanbanItemsKey } from '~/constants/react-query-keys';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { currentKanbanGroupByCustomFieldIdSelector, currentViewIdSelector } from '~/store/configViews/selectors';
import { KanbanColumnsWithInitialData } from '~/types/KanbanData';
import { useAirSelector } from '~/utils/ReduxUtils';

/** This is agnostic to the private vs public request */
export const useGetInitialKanbanItems = () => {
  const customFieldId = useAirSelector(currentKanbanGroupByCustomFieldIdSelector);
  const queryClient = useQueryClient();

  const viewId = useAirSelector(currentViewIdSelector);
  const boardId = useURLBoardIdSelector();

  const key = useMemo(
    () => getInitialKanbanItemsKey({ customFieldId, viewId, boardId }),
    [boardId, customFieldId, viewId],
  );

  const result = useQuery({
    queryKey: key,
    queryFn: () => queryClient.getQueryData<KanbanColumnsWithInitialData>(key),
    enabled: !!customFieldId && !!viewId && !!boardId,
  });

  return useMemo(() => {
    return { ...result, key };
  }, [result, key]);
};
