import { PublicClip } from '@air/api';
import { Clip } from '@air/api/types';
import { CSSProperties, memo } from 'react';

import { FileGalleryCard, FileGalleryCardProps } from '~/components/Gallery/FileGalleryCard/FileGalleryCard';
import { KANBAN_ITEM_CARD_WIDTH } from '~/components/KanbanView/shared/kanbanConstants';
import { DndSortableKanbanItemData } from '~/types/DndKit';

import { DndSortableKanbanItem } from './DndSortableKanbanItem';

interface KanbanFileComponentProps<C> extends Omit<FileGalleryCardProps<C>, 'cardWidth'> {
  dndDisabled?: boolean;
  kanbanDndItemData: DndSortableKanbanItemData;
  style: CSSProperties;
}

const _KanbanFileComponent = <C extends Clip | PublicClip>({
  dndDisabled,
  kanbanDndItemData,
  style,
  cardHeight,
  ...rest
}: KanbanFileComponentProps<C>) => {
  return (
    <DndSortableKanbanItem
      style={style}
      id={kanbanDndItemData.sortableKanbanItemKey}
      data={kanbanDndItemData}
      dndDisabled={dndDisabled}
    >
      <div className="relative" style={{ height: cardHeight }}>
        <FileGalleryCard
          cardWidth={KANBAN_ITEM_CARD_WIDTH}
          getButtonClassName={() =>
            'bg-grey-1 dark:border dark:border-grey-4 shadow-md cursor-pointer shadow-[0_1px_8px_rgba(0,0,0,0.1),0_0_2px_rgba(0,0,0,0.15)]'
          }
          cardHeight={cardHeight}
          {...rest}
        />
      </div>
    </DndSortableKanbanItem>
  );
};

export const KanbanFileComponent = memo(_KanbanFileComponent) as typeof _KanbanFileComponent;
