import { AirActionTrackingLocation, useTrackDownloadedItems } from '@air/analytics';
import { useCallback } from 'react';

import { useCreatePublicZippingTask } from '~/components/ZippingTasks/hooks/useCreatePublicZippingTask';

interface DownloadPublicBoardParams {
  /** Id of the board who you want to download */
  boardId: string;
  /** Where in the UI this was called from */
  trackLocation: AirActionTrackingLocation;
}

/**
 * This hook returns a function that downloads a single public board.
 * If you are trying to download multiple boards, @see useDownloadPublicItems.
 */
export function useDownloadPublicBoard() {
  const { trackDownloadedItems } = useTrackDownloadedItems();
  const { createPublicZippingTask } = useCreatePublicZippingTask();

  const downloadPublicBoard = useCallback(
    ({ boardId, trackLocation }: DownloadPublicBoardParams) => {
      createPublicZippingTask({
        boardIds: [boardId],
        clipIds: undefined,
      });

      trackDownloadedItems({
        boardIds: [boardId],
        location: trackLocation,
      });
    },
    [createPublicZippingTask, trackDownloadedItems],
  );

  return {
    downloadPublicBoard,
  };
}
