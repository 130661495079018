import { AirActionTrackingLocation, useTrackDownloadedItems } from '@air/analytics';
import { ShortUrl } from '@air/api';
import { useToasts } from '@air/provider-toast';
import { useCallback } from 'react';

import { DownloadFile, isS3DownloadUrlActive } from '~/utils/DownloadFile';

interface UseDownloadPublicAssetParams {
  /** Id of the clip to download */
  clipId: string;
  /** ShortId of the clip you want to download */
  shortId: string;
  /** Where in the UI this was called from */
  trackLocation: AirActionTrackingLocation;
}

/**
 * This hook returns a function that downloads a single public asset.
 * If you are trying to download multiple public assets, @see useDownloadPublicItems
 */
export function useDownloadPublicAsset() {
  const { trackDownloadedItems } = useTrackDownloadedItems();
  const { showToast } = useToasts();

  const downloadPublicAsset = useCallback(
    async ({ trackLocation, shortId, clipId }: UseDownloadPublicAssetParams) => {
      const { url } = await ShortUrl.getDownloadUrl(shortId, clipId);

      if (isS3DownloadUrlActive(url)) {
        DownloadFile(url);

        trackDownloadedItems({
          clipIds: [clipId],
          location: trackLocation,
        });
      } else {
        showToast('One or more of the selected file downloads has expired.');
      }
    },
    [showToast, trackDownloadedItems],
  );

  return {
    downloadPublicAsset,
  };
}
