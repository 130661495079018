import { ShortUrl } from '@air/api';
import { useCallback } from 'react';

import { CTWTask, GetBaseMetadata } from '~/store/tasks/types';
import { useCreateTask } from '~/utils/taskUtils/useCreateTask';

export type CreateCopyToWorkspaceTaskParams = GetBaseMetadata<CTWTask> & {
  shortId: string;
};

export const useCreateCopyToWorkspaceTask = () => {
  const { createTask } = useCreateTask();

  const createCopyToWorkspaceTask = useCallback(
    async ({
      sourceClipIds,
      sourceAssetIds,
      sourceBoardIds,
      targetWorkspaceId,
      targetWorkspaceName,
      shortId,
    }: CreateCopyToWorkspaceTaskParams) => {
      return createTask({
        task: {
          type: 'CopyToWorkspace',
          shortId,
          metadata: {
            targetWorkspaceName,
            targetWorkspaceId,
            sourceBoardIds,
            sourceAssetIds,
            sourceClipIds,
          },
        },
        createTask: () =>
          ShortUrl.Tasks.createTask(shortId, {
            type: 'ContentDuplicator',
            args: {
              targetWorkspaceId,
              sourceBoardIds,
              sourceAssetIds,
              sourceClipIds,
            },
          }),
      });
    },
    [createTask],
  );

  return {
    createCopyToWorkspaceTask,
  };
};
