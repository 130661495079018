import { PublicClip } from '@air/api';
import { Board, Clip, PublicBoard } from '@air/api/types';
import { uploadByIdSelector } from '@air/redux-uploader';
import { CSSProperties, memo } from 'react';

import { KanbanUploadingAssetComponent } from '~/components/KanbanView/KanbanColumnsManagers/shared/KanbanColumnItems/KanbanItem/shared/KanbanUploadingAssetComponent';
import { KanbanUploadingFileComponent } from '~/components/KanbanView/KanbanColumnsManagers/shared/KanbanColumnItems/KanbanItem/shared/KanbanUploadingFileComponent';
import { useURLShortIdSelector } from '~/hooks/useURLShortIdSelector';
import { DndItemType, DndSortableKanbanItemData } from '~/types/DndKit';
import { useAirStore } from '~/utils/ReduxUtils';

import { PrivateKanbanAsset } from './PrivateKanbanAsset';
import { PrivateKanbanBoard } from './PrivateKanbanBoard';
import { PrivateKanbanFile } from './PrivateKanbanFile';
import { PublicKanbanAsset } from './PublicKanbanAsset';
import { PublicKanbanBoard } from './PublicKanbanBoard';
import { PublicKanbanFile } from './PublicKanbanFile';

interface KanbanItemProps {
  cardHeight: number;
  kanbanDndItemData: DndSortableKanbanItemData;
  style: CSSProperties;
}
/* eslint-disable react/prop-types */

export const KanbanItem = memo<KanbanItemProps>(({ cardHeight, kanbanDndItemData, style }: KanbanItemProps) => {
  const shortId = useURLShortIdSelector();
  const store = useAirStore();

  const apiItemDataObject = kanbanDndItemData.apiData.data;

  if (shortId) {
    switch (kanbanDndItemData.dndType) {
      case DndItemType.kanbanAsset: {
        return (
          <PublicKanbanAsset
            cardHeight={cardHeight}
            asset={apiItemDataObject as PublicClip}
            kanbanDndItemData={kanbanDndItemData}
            style={style}
          />
        );
      }
      case DndItemType.kanbanBoard: {
        return (
          <PublicKanbanBoard
            board={apiItemDataObject as PublicBoard}
            kanbanDndItemData={kanbanDndItemData}
            style={style}
          />
        );
      }
      case DndItemType.kanbanFile: {
        return (
          <PublicKanbanFile
            cardHeight={cardHeight}
            file={apiItemDataObject as PublicClip}
            kanbanDndItemData={kanbanDndItemData}
            style={style}
          />
        );
      }
    }
  }

  switch (kanbanDndItemData.dndType) {
    case DndItemType.kanbanAsset: {
      return (
        <PrivateKanbanAsset
          cardHeight={cardHeight}
          asset={apiItemDataObject as Clip}
          kanbanDndItemData={kanbanDndItemData}
          style={style}
        />
      );
    }
    case DndItemType.kanbanBoard: {
      return (
        <PrivateKanbanBoard board={apiItemDataObject as Board} kanbanDndItemData={kanbanDndItemData} style={style} />
      );
    }
    case DndItemType.kanbanFile: {
      return (
        <PrivateKanbanFile
          cardHeight={cardHeight}
          file={apiItemDataObject as Clip}
          kanbanDndItemData={kanbanDndItemData}
          style={style}
        />
      );
    }
    case DndItemType.kanbanUpload: {
      const upload = uploadByIdSelector(store.getState(), kanbanDndItemData.itemId);

      if (!upload) {
        return null;
      }

      if (upload.isNonMedia) {
        return <KanbanUploadingFileComponent upload={upload} kanbanDndItemData={kanbanDndItemData} style={style} />;
      }

      return <KanbanUploadingAssetComponent upload={upload} kanbanDndItemData={kanbanDndItemData} style={style} />;
    }
  }
});
/* eslint-enable react/prop-types */

KanbanItem.displayName = 'KanbanItem';
