import { Items, ShortUrl } from '@air/api';
import { ClipAndBoardListOptions, ClipType } from '@air/api/types';
import { skipToken, useInfiniteQuery } from '@tanstack/react-query';
import { useDeepCompareMemo } from 'use-deep-compare';

import { getColumnFiltersForGroupedSearch } from '~/components/KanbanView/KanbanColumnsManagers/KanbanColumnsManager/shared/getColumnFiltersForGroupedSearch';
import { useGetInitialKanbanItems } from '~/components/KanbanView/KanbanColumnsManagers/KanbanColumnsManager/shared/useGetInitialKanbanItems';
import { useKanbanContext } from '~/components/KanbanView/Providers/KanbanProvider';
import { defaultKanbanSort, KANBAN_COLUMN_FETCH_LIMIT } from '~/components/KanbanView/shared/kanbanConstants';
import { getKanbanColumnItemsKey } from '~/constants/react-query-keys';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { useURLShortIdSelector } from '~/hooks/useURLShortIdSelector';
import {
  currentKanbanGroupByCustomFieldIdSelector,
  currentSortFieldSelector,
  currentViewIdSelector,
} from '~/store/configViews/selectors';
import { useBoardSearchParams } from '~/swr-hooks/search/useBoardSearchParams';
import { useClipSearchParams } from '~/swr-hooks/search/useClipSearchParams';
import { KanbanColumnApiData } from '~/types/KanbanData';
import { useAirSelector } from '~/utils/ReduxUtils';

type PaginatedKanbanColumnItemsFetcher = (args: {
  shortId?: string;
  boardId?: string;
  customFieldId: string;
  visibleColumnId: string;
  viewId?: string;
  searchParams: Partial<ClipAndBoardListOptions>;
  pageParam: string | null;
  workspaceId: string | undefined;
}) => Promise<KanbanColumnApiData>;

const kanbanColumnItemsFetcher: PaginatedKanbanColumnItemsFetcher = async ({
  shortId,
  boardId,
  customFieldId,
  visibleColumnId,
  viewId,
  searchParams,
  pageParam,
  workspaceId,
}) => {
  if (!customFieldId || !visibleColumnId) return { data: [], pagination: { hasMore: false, cursor: null }, total: 0 };
  const params: Partial<ClipAndBoardListOptions> = {
    ...searchParams,
    limit: KANBAN_COLUMN_FETCH_LIMIT,
    filters: getColumnFiltersForGroupedSearch({ customFieldId, visibleColumnId }),
    cursor: pageParam,
    viewId,
    boardId,
    types: Object.keys(ClipType) as ClipType[],
  };
  if (shortId) return ShortUrl.searchClipsAndBoardsByShortId(shortId, params);
  if (!workspaceId) {
    throw new Error('workspaceId is required');
  }

  return Items.searchClipsAndBoards({
    options: params,
    workspaceId,
  });
};

/**
 * This returns useSWRInfinite for the fetching of items in one column.
 */
export const useGetKanbanColumnItems = ({ kanbanColumnId }: { kanbanColumnId: string }) => {
  const shortId = useURLShortIdSelector();

  const customFieldId = useAirSelector(currentKanbanGroupByCustomFieldIdSelector);

  const { data: initialItemsResponse } = useGetInitialKanbanItems();
  const initialItems = initialItemsResponse?.[kanbanColumnId];

  const viewId = useAirSelector(currentViewIdSelector);
  const boardId = useURLBoardIdSelector();
  const sortField = useAirSelector(currentSortFieldSelector);
  const clipsFetchParams = useClipSearchParams();
  const boardsFetchParams = useBoardSearchParams();
  const { workspaceId } = useKanbanContext();

  const searchParams = useDeepCompareMemo<Partial<ClipAndBoardListOptions>>(
    () => ({
      ...clipsFetchParams,
      ...boardsFetchParams,
      sortBy: undefined, // ensure we use the new sort fieldName and direction
      sortField: sortField || defaultKanbanSort,
    }),
    [sortField, clipsFetchParams, boardsFetchParams],
  );

  const queryKey = getKanbanColumnItemsKey({
    boardId,
    viewId,
    customFieldId,
    kanbanColumnId,
    ...searchParams.sortField,
  });

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey,
    queryFn:
      !!customFieldId && !!workspaceId
        ? ({ pageParam }) =>
            kanbanColumnItemsFetcher({
              shortId,
              boardId,
              customFieldId,
              viewId,
              visibleColumnId: kanbanColumnId,
              searchParams,
              pageParam: pageParam || null,
              workspaceId,
            })
        : skipToken,
    initialData: initialItems ? { pages: [initialItems.data], pageParams: [] } : undefined,
    enabled: !!initialItems && !!boardId && !!viewId && !!customFieldId && !!workspaceId,
    getNextPageParam: (previousData) => previousData?.pagination?.cursor,
    initialPageParam: '',
  });

  return {
    queryKey,
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  };
};
