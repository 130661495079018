import { BoardAncestorsDropdown } from '~/components/Workspace/BoardHeader/BoardCrumbs/BoardAncestorsDropdown';
import { BoardCrumbsProps } from '~/components/Workspace/BoardHeader/BoardCrumbs/index';

type ShortBoardCrumbsProps = BoardCrumbsProps;

export const ShortBoardCrumbs = ({ onBoardClick, ancestors, title }: ShortBoardCrumbsProps) => (
  <div className="flex items-center gap-1">
    {ancestors.length ? (
      <>
        <BoardAncestorsDropdown ancestors={ancestors} onBoardClick={onBoardClick} />
        <span className="text-12 text-grey-8">/</span>
      </>
    ) : null}
    <div className="flex-1 truncate px-1 text-12 font-medium text-grey-11">{title}</div>
  </div>
);
