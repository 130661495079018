import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentProps, Fragment, memo, type ReactNode } from 'react';

/**
 * This should be in sync with the class name on `BoardStatsBar` below.
 */
export const BOARD_STATS_BAR_HEIGHT = 56;

export type BoardStatsBarStat = {
  id: string;
  label: string;
  value: string;
};

export type BoardStatsBarProps = Pick<ComponentProps<'div'>, 'className'> & {
  actions: ReactNode;
  stats: BoardStatsBarStat[];
};

export const BoardStatsBar = memo(({ actions, className, stats }: BoardStatsBarProps) => {
  return (
    <div
      className={tailwindMerge(
        'sticky bottom-0 flex h-14 w-full shrink-0 items-center justify-between border-t border-t-grey-4 bg-grey-1 px-3 md:px-12',
        className,
      )}
      data-disableselect
      data-testid="BOARD_STATS_BAR"
    >
      <div className="flex flex-wrap gap-1 text-14 font-medium text-grey-9">
        {stats?.map((stat) => {
          const isLast = stats.indexOf(stat) === stats.length - 1;
          const element = <div key={stat.id}>{stat.label}</div>;

          if (isLast) {
            return element;
          }

          return (
            <Fragment key={stat.id}>
              <span>{element}</span>
              <span>&middot;</span>
            </Fragment>
          );
        })}
      </div>

      {!!actions && <div className="flex shrink-0 gap-2">{actions}</div>}
    </div>
  );
});

BoardStatsBar.displayName = 'BoardStatsBar';
