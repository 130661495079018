import { CSSProperties, memo } from 'react';

import { UploadingFileGalleryCard, UploadingFileGalleryCardProps } from '~/components/Gallery/UploadingFileGalleryCard';
import { KANBAN_FILE_CARD_HEIGHT, KANBAN_ITEM_CARD_WIDTH } from '~/components/KanbanView/shared/kanbanConstants';
import { DndSortableKanbanItemData } from '~/types/DndKit';

import { DndSortableKanbanItem } from './DndSortableKanbanItem';

interface KanbanFileComponentProps extends Omit<UploadingFileGalleryCardProps, 'width'> {
  kanbanDndItemData: DndSortableKanbanItemData;
  style: CSSProperties;
}

const _KanbanUploadingFileComponent = ({ kanbanDndItemData, style, upload }: KanbanFileComponentProps) => {
  return (
    <DndSortableKanbanItem
      style={style}
      id={kanbanDndItemData.sortableKanbanItemKey}
      data={kanbanDndItemData}
      dndDisabled={true}
    >
      <div className="relative" style={{ height: KANBAN_FILE_CARD_HEIGHT }}>
        <UploadingFileGalleryCard
          width={KANBAN_ITEM_CARD_WIDTH}
          upload={upload}
          getClassName={() =>
            'bg-white shadow-md cursor-pointer shadow-[0_1px_8px_rgba(0,0,0,0.1),0_0_2px_rgba(0,0,0,0.15)]'
          }
        />
      </div>
    </DndSortableKanbanItem>
  );
};

export const KanbanUploadingFileComponent = memo(_KanbanUploadingFileComponent) as typeof _KanbanUploadingFileComponent;
