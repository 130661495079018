import { BaseCustomField, Board, Clip, CustomFieldValue } from '@air/api/types';
import produce from 'immer';

import { DndSortableKanbanItemData } from '~/types/DndKit';
import { updateBoardCustomFieldsWithValue, updateClipCustomFieldsWithValue } from '~/utils/CustomFields';

// this is used to mock the result after the backend changes the item custom field value
export const getChangedKanbanItem = ({
  activeData,
  baseCustomField,
  customFieldValues,
  nextKanbanColumnId,
}: {
  activeData: DndSortableKanbanItemData;
  baseCustomField: BaseCustomField | undefined;
  customFieldValues: CustomFieldValue[];
  nextKanbanColumnId: string;
}): DndSortableKanbanItemData => {
  const apiData = produce(activeData.apiData, (draft) => {
    if (baseCustomField) {
      const cfValue = customFieldValues.find((cf) => cf.id === nextKanbanColumnId);
      switch (draft.type) {
        case 'asset':
        case 'file': {
          const data = draft.data as Clip;
          data.customFields = updateClipCustomFieldsWithValue({
            baseCustomField,
            assetId: data.assetId,
            customFieldValues: data.customFields,
            nextValue: cfValue,
          });
          draft.data = data;
          break;
        }
        case 'board': {
          const data = draft.data as Board;
          data.customFields = updateBoardCustomFieldsWithValue({
            baseCustomField,
            boardId: data.id,
            customFieldValues: data.customFields,
            nextValue: cfValue,
          });
          draft.data = data;
          break;
        }
      }
    }
  });
  return {
    ...activeData,
    apiData,
  };
};
