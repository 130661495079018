import { Board } from '@air/api/types';
import { Ellipsis } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { memo } from 'react';

import { DropdownMenuWithActionSheet } from '~/components/Menus/DropdownMenuWithActionSheet';

export interface BoardAncestorsDropdownProps {
  ancestors: Pick<Board, 'id' | 'title'>[];
  onBoardClick: (board: Pick<Board, 'id' | 'title'>) => void;
}

export const BoardAncestorsDropdown = memo(({ ancestors, onBoardClick }: BoardAncestorsDropdownProps) => (
  <DropdownMenuWithActionSheet
    isTitleHidden
    options={ancestors.map(({ id, title }) => ({
      id,
      label: title,
      onSelect: () => onBoardClick({ id, title }),
      type: 'item',
    }))}
    title="Ancestor"
    trigger={
      <IconButton appearance="ghost" color="grey" icon={Ellipsis} label="See more boards in pop-up" size="small" />
    }
  />
));

BoardAncestorsDropdown.displayName = 'BoardAncestorsDropdowns';
