import { useRouter } from 'next/router';
import { useCallback } from 'react';

const queryKey = 'showCustomFieldsPanel';

export const useCustomFieldsPanel = () => {
  const { query, replace } = useRouter();

  const isPanelOpen = query[queryKey] === 'true';

  const closePanel = useCallback(() => {
    const queryParams = { ...query };
    delete queryParams[queryKey];

    replace(
      {
        query: queryParams,
      },
      undefined,
      { shallow: true },
    );
  }, [query, replace]);

  const openPanel = useCallback(() => {
    replace(
      {
        query: {
          ...query,
          [queryKey]: true,
        },
      },
      undefined,
      { shallow: true },
    );
  }, [query, replace]);

  const togglePanel = useCallback(() => {
    if (isPanelOpen) {
      closePanel();
    } else {
      openPanel?.();
    }
  }, [closePanel, isPanelOpen, openPanel]);

  return {
    togglePanel,
    openPanel,
    closePanel,
    queryKey,
    isPanelOpen,
  };
};
