import { Board } from '@air/api/types';
import { memo, useCallback } from 'react';
import isEqual from 'react-fast-compare';

import { PrivateGalleryBoardAncestors } from '~/components/PrivateGallery/PrivateGalleryBoardAncestors';
import { useGetPrivateBoardMenuOptionsCallback } from '~/components/PrivateGallery/useGetPrivateBoardMenuOptionsCallback';
import { useIsSearchActive } from '~/hooks/search/useIsSearchActive';
import { useBoardPermissions } from '~/hooks/useBoardPermissions';
import { useGoToBoardPage } from '~/hooks/useGoToBoardPage';
import { useOpenBoardInNewTab } from '~/hooks/useOpenBoardInNewTab';
import { usePrivateSelectionMenuOptions } from '~/hooks/usePrivateSelectionMenuOptions';
import { canChangeBoardCustomFields, canSeeBoardCustomFields } from '~/utils/permissions/boardPermissions';
import { isMobileAgent } from '~/utils/PlatformHelpers';

import { KanbanBoardComponent } from './shared/KanbanBoardComponent';
import { KanbanBoardProps } from './shared/kanbanItemTypes';

export const PrivateKanbanBoard = memo(({ board, kanbanDndItemData, style }: KanbanBoardProps<Board>) => {
  const { boardPermissions } = useBoardPermissions({
    boardId: board.id,
  });
  const { isSearchActive } = useIsSearchActive();
  const { getSelectionMenuOptions } = usePrivateSelectionMenuOptions();
  const { getBoardMenuOptionsCallback } = useGetPrivateBoardMenuOptionsCallback();
  const _getItemMenuOptions = getBoardMenuOptionsCallback({ item: board });
  const { goToBoardPage } = useGoToBoardPage();
  const { openBoardInNewTab } = useOpenBoardInNewTab();
  const onBoardClick = useCallback(
    () => goToBoardPage({ board, trackLocation: 'gallery-item' }),
    [board, goToBoardPage],
  );
  const onBoardCmdClick = useCallback(
    () => openBoardInNewTab({ board, trackLocation: 'gallery-item' }),
    [board, openBoardInNewTab],
  );

  const canViewCustomFields = canSeeBoardCustomFields(boardPermissions);
  const canChangeCustomFields = canChangeBoardCustomFields(boardPermissions);

  return (
    <KanbanBoardComponent
      board={board}
      getItemMenuOptions={_getItemMenuOptions}
      getSelectionMenuOptions={getSelectionMenuOptions}
      kanbanDndItemData={kanbanDndItemData}
      onClick={onBoardClick}
      onCmdClickTitle={onBoardCmdClick}
      isSelectable={false}
      ancestors={
        isSearchActive ? (
          <PrivateGalleryBoardAncestors libraryTitle={board.library?.title} ancestors={board.ancestors} />
        ) : undefined
      }
      style={style}
      dndDisabled={isMobileAgent || !canChangeCustomFields}
      canViewCustomFields={canViewCustomFields}
    />
  );
}, isEqual);

PrivateKanbanBoard.displayName = 'PrivateKanbanBoard';
