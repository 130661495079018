import { ClipAndBoardListItemType, ClipType } from '@air/api/types';

import { KanbanColumnApiData } from '~/types/KanbanData';

const extractType = (data: object, type: ClipAndBoardListItemType) => {
  if (type === 'board') return 'board';
  // @ts-ignore we are checking for the type on object
  const dataType = data.type;
  if (dataType === ClipType.nonMedia) {
    return 'file';
  }
  return 'asset';
};

/**
 * files come through a type: 'asset', so we must convert them here
 */
export const convertKanbanItemNonMediaTypes = ({ data, ...rest }: KanbanColumnApiData): KanbanColumnApiData => ({
  data: data.map(({ data: itemData, id, type }) => ({
    id,
    type: extractType(itemData, type),
    data: itemData,
  })),
  ...rest,
});
