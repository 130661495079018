import { useDndContext } from '@dnd-kit/core';
import { memo, useEffect, useRef } from 'react';
import { AutoSizer } from 'react-virtualized';
import { VariableSizeList } from 'react-window';

import { useKanbanFetchPageContext } from '~/components/KanbanView/KanbanColumnsManagers/shared/KanbanFetchPageContext';
import { visibleCustomFieldsLengthSelector } from '~/store/configViews/selectors';
import { kanbanColumnItemsSelector } from '~/store/kanbanManager/selectors';
import { useAirSelector } from '~/utils/ReduxUtils';

import { useRenderKanbanItem } from './useRenderKanbanItem';

interface KanbanColumnItems {
  kanbanColumnId: string;
}
export const KanbanColumnItems = memo(({ kanbanColumnId }: KanbanColumnItems) => {
  const { isFetchingNextPage, fetchNextPage, hasNextPage } = useKanbanFetchPageContext();

  const { renderKanbanItem, getItemKey, getItemSize } = useRenderKanbanItem({
    kanbanColumnId,
    fetchNextPage,
    isFetchingNextPage,
  });

  const { over } = useDndContext();
  const kanbanItems = useAirSelector((state) => kanbanColumnItemsSelector(state, kanbanColumnId));
  const visibleCustomFieldsLength = useAirSelector(visibleCustomFieldsLengthSelector);
  const listRef = useRef<VariableSizeList | null>(null);

  useEffect(() => {
    // redraw heights of list
    // may need to change if things get resorted
    listRef.current?.resetAfterIndex(0);
  }, [over, visibleCustomFieldsLength, kanbanItems, kanbanColumnId]);

  if (kanbanItems.length === 0) return null;
  return (
    <div className="grow">
      <AutoSizer>
        {({ height, width }) => (
          <VariableSizeList
            className="no-scrollbar box-border"
            ref={listRef}
            data-testid="var-size-list"
            itemKey={getItemKey}
            height={height}
            itemCount={hasNextPage ? kanbanItems.length + 1 : kanbanItems.length}
            itemSize={getItemSize}
            width={width}
          >
            {renderKanbanItem}
          </VariableSizeList>
        )}
      </AutoSizer>
    </div>
  );
});

KanbanColumnItems.displayName = 'KanbanColumnItems';
