import { ShortUrlObjectType } from '@air/api/types';
import { memo, useEffect } from 'react';

import { stopEscKeyPropagationOnAssetDetailsModal } from '~/components/AssetModal/shared/hooks/useAssetModalEscKeyHandler';

import { SharePublicItemModal, SharePublicItemModalProps } from '../SharePublicItemModal';

export interface SharePublicClipModalProps extends AirModalProps, Omit<SharePublicItemModalProps, 'type'> {}

export const SharePublicClipModal = memo((props: SharePublicClipModalProps) => {
  useEffect(() => {
    stopEscKeyPropagationOnAssetDetailsModal(true);
    return () => {
      stopEscKeyPropagationOnAssetDetailsModal(false);
    };
  }, []);

  return <SharePublicItemModal {...props} type={ShortUrlObjectType.clip} />;
});

SharePublicClipModal.displayName = 'SharePublicClipModal';
