import { Items, ShortUrl } from '@air/api';
import {
  AssetSearchFilters,
  BoardSearchFilters,
  ClipAndBoardListOptions,
  ClipType,
  SearchClipsAndBoardsGroupedResponse,
  SearchClipsAndBoardsWithGroupingArgs,
} from '@air/api/types';
import { useToasts } from '@air/provider-toast';
import { useQueryClient } from '@tanstack/react-query';
import produce from 'immer';
import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { useKanbanContext } from '~/components/KanbanView/Providers/KanbanProvider';
import { defaultKanbanSort, KANBAN_COLUMN_FETCH_LIMIT } from '~/components/KanbanView/shared/kanbanConstants';
import { currentKanbanGroupByCustomFieldIdSelector, currentViewIdSelector } from '~/store/configViews/selectors';
import { setGroupedItemsResponseInKanbanManagerAction } from '~/store/kanbanManager/actions';
import { KanbanColumnsWithInitialData } from '~/types/KanbanData';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { getBoardIdFromPath, getShortIdFromPath } from '~/utils/PathUtils';
import { useAirSelector, useAirStore } from '~/utils/ReduxUtils';

import { getColumnFiltersForGroupedSearch } from '../shared/getColumnFiltersForGroupedSearch';
import { useGetInitialKanbanItems } from '../shared/useGetInitialKanbanItems';
import { convertKanbanItemNonMediaTypes } from './convertKanbanItemNonMediaTypes';

const updateInitialKanbanItemsCache = ({
  columnsWithInitialData,
}: {
  columnsWithInitialData: KanbanColumnsWithInitialData;
}) => {
  return (cachedItems: KanbanColumnsWithInitialData = {}) =>
    produce(cachedItems, (draft) => {
      Object.keys(columnsWithInitialData).forEach((key) => {
        draft[key] = columnsWithInitialData[key];
      });
    });
};

const mapResponseToColumnGroup = ({
  groupOfVisibleColumnIds,
  groupedResponse,
}: {
  groupOfVisibleColumnIds: string[];
  groupedResponse: SearchClipsAndBoardsGroupedResponse;
}): KanbanColumnsWithInitialData => {
  const { groupings } = groupedResponse;
  return groupOfVisibleColumnIds.reduce<KanbanColumnsWithInitialData>((next, key, index) => {
    const convertedListResponse = convertKanbanItemNonMediaTypes(groupings[index]);
    next[key] = {
      data: convertedListResponse,
      total: convertedListResponse.total,
    };
    return next;
  }, {});
};
const getGroupedFilters = ({
  customFieldId,
  groupOfVisibleColumnIds,
}: {
  customFieldId: string;
  groupOfVisibleColumnIds: string[]; // this is not from the kanbanColumnsSelector because it should be a limited set
}): (AssetSearchFilters & BoardSearchFilters)[] =>
  groupOfVisibleColumnIds.map((visibleColumnId) =>
    getColumnFiltersForGroupedSearch({ customFieldId, visibleColumnId }),
  );

type LoadKanbanColumnGroupFunc = (args: {
  groupOfVisibleColumnIds: string[];
  searchParams?: Partial<ClipAndBoardListOptions>;
}) => Promise<void>;

export const useLoadKanbanColumnGroup = () => {
  const { showToast } = useToasts();
  const customFieldId = useAirSelector(currentKanbanGroupByCustomFieldIdSelector);
  const dispatch = useDispatch();
  const { getState } = useAirStore();
  const queryClient = useQueryClient();
  const { key, refetch } = useGetInitialKanbanItems();
  const isFetchingRef = useRef(false);
  const { workspaceId } = useKanbanContext();

  const loadColumnGroup: LoadKanbanColumnGroupFunc = useCallback(
    async ({ groupOfVisibleColumnIds, searchParams }) => {
      if (!customFieldId) return;
      const shortId = getShortIdFromPath(window.location.pathname);
      const viewId = currentViewIdSelector(getState());
      const boardId = getBoardIdFromPath(window.location.pathname);
      if (!boardId) return;
      const groupedFilters = getGroupedFilters({
        customFieldId,
        groupOfVisibleColumnIds,
      });
      const params: SearchClipsAndBoardsWithGroupingArgs = {
        ...searchParams,
        filters: { board: { is: boardId } },
        sortBy: undefined, // ensure we use the new sort fieldName and direction
        limit: KANBAN_COLUMN_FETCH_LIMIT,
        groupedFilters,
        viewId,
        boardId,
        types: Object.keys(ClipType) as ClipType[],
        sortField: searchParams?.sortField || defaultKanbanSort,
      };
      if (isFetchingRef.current) return;
      try {
        isFetchingRef.current = true;
        /**
         * The duplicated logic below should be broken out when the apps (private/public) get split apart
         */
        if (shortId) {
          const groupedResponse = await ShortUrl.searchClipsAndBoardsWithGroupingByShortId(shortId, params);

          isFetchingRef.current = false;
          const columnsWithInitialData = mapResponseToColumnGroup({ groupOfVisibleColumnIds, groupedResponse });
          dispatch(setGroupedItemsResponseInKanbanManagerAction({ columnsWithInitialData }));
          // set cache with key
          queryClient.setQueryData(key, updateInitialKanbanItemsCache({ columnsWithInitialData }));
          refetch();
        } else if (workspaceId) {
          const groupedResponse = await Items.searchClipsAndBoardsWithGrouping({ workspaceId, options: params });

          isFetchingRef.current = false;
          const columnsWithInitialData = mapResponseToColumnGroup({ groupOfVisibleColumnIds, groupedResponse });
          dispatch(setGroupedItemsResponseInKanbanManagerAction({ columnsWithInitialData }));
          // set cache with key
          queryClient.setQueryData(key, updateInitialKanbanItemsCache({ columnsWithInitialData }));
          refetch();
        }
      } catch (error) {
        showToast(`There was an error loading items.`);

        reportErrorToBugsnag({
          error,
          context: `Failed to search grouped response`,
          metadata: {
            data: {
              groupedFilters,
              viewId,
              boardId,
              shortId,
            },
          },
        });
      }
    },

    [customFieldId, getState, workspaceId, dispatch, queryClient, key, refetch, showToast],
  );
  return { loadColumnGroup };
};
