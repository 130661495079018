import { Board, PublicBoard } from '@air/api/types';
import { CSSProperties, memo } from 'react';

import { BoardGalleryCard, BoardGalleryCardProps } from '~/components/Gallery/BoardGalleryCard/BoardGalleryCard';
import { KANBAN_BOARD_CARD_HEIGHT, KANBAN_ITEM_CARD_WIDTH } from '~/components/KanbanView/shared/kanbanConstants';
import { DndSortableKanbanItemData } from '~/types/DndKit';

import { DndSortableKanbanItem } from './DndSortableKanbanItem';

interface KanbanBoardComponentProps<B extends Board | PublicBoard> extends Omit<BoardGalleryCardProps<B>, 'cardWidth'> {
  dndDisabled?: boolean;
  kanbanDndItemData: DndSortableKanbanItemData;
  style: CSSProperties;
}
function _KanbanBoardComponent<B extends Board | PublicBoard>({
  dndDisabled,
  kanbanDndItemData,
  style,
  ...rest
}: KanbanBoardComponentProps<B>) {
  return (
    <DndSortableKanbanItem
      id={kanbanDndItemData.sortableKanbanItemKey}
      data={kanbanDndItemData}
      dndDisabled={dndDisabled}
      style={style}
    >
      <div className="relative" style={{ height: KANBAN_BOARD_CARD_HEIGHT }}>
        <BoardGalleryCard
          cardWidth={KANBAN_ITEM_CARD_WIDTH}
          getButtonClassName={() =>
            'bg-grey-1 dark:border dark:border-grey-4 shadow-md cursor-pointer shadow-[0_1px_8px_rgba(0,0,0,0.1),0_0_2px_rgba(0,0,0,0.15)]'
          }
          {...rest}
        />
      </div>
    </DndSortableKanbanItem>
  );
}

export const KanbanBoardComponent = memo(_KanbanBoardComponent) as typeof _KanbanBoardComponent;
