import { Clip } from '@air/api/types';
import { memo, useCallback } from 'react';
import isEqual from 'react-fast-compare';

import { useGetPrivateAssetMenuOptionsCallback } from '~/components/PrivateGallery/useGetPrivateAssetMenuOptionsCallback';
import { QueryParamNames } from '~/constants/search';
import { useClipPermissions } from '~/hooks/useClipPermissions';
import { useGoToAssetPage } from '~/hooks/useGoToAssetPage';
import { useOpenAssetInNewTab } from '~/hooks/useOpenAssetInNewTab';
import { usePrivateSelectionMenuOptions } from '~/hooks/usePrivateSelectionMenuOptions';
import { AssetModalPanel } from '~/providers/AssetModalPanelContextProvider';
import { usePrivateCustomFieldsContext } from '~/providers/PrivateCustomFieldsProvider';
import {
  canChangeAssetCustomFields,
  canSeeAssetCustomFields,
  canViewAssetDiscussions,
} from '~/utils/permissions/assetPermissions';
import { isMobileAgent } from '~/utils/PlatformHelpers';

import { KanbanFileComponent } from './shared/KanbanFileComponent';
import { KanbanFileProps } from './shared/kanbanItemTypes';

export const PrivateKanbanFile = memo(({ cardHeight, file, kanbanDndItemData, style }: KanbanFileProps<Clip>) => {
  const { clipPermissions } = useClipPermissions({
    clipId: file.id,
  });
  const { customFields } = usePrivateCustomFieldsContext();
  const { getSelectionMenuOptions } = usePrivateSelectionMenuOptions();
  const { getAssetMenuOptionsCallback } = useGetPrivateAssetMenuOptionsCallback();
  const _getItemMenuOptions = getAssetMenuOptionsCallback({ item: file });
  const { goToAssetPage } = useGoToAssetPage();
  const { openAssetInNewTab } = useOpenAssetInNewTab();

  const onCustomFieldsClick = useCallback(
    () =>
      goToAssetPage({
        asset: file,
        query: {
          [QueryParamNames.tab]: AssetModalPanel.FIELDS,
        },
        trackLocation: 'custom-field-click',
      }),
    [file, goToAssetPage],
  );

  const onItemClick = useCallback(
    () =>
      goToAssetPage({
        asset: file,
        trackLocation: 'card-click',
      }),
    [file, goToAssetPage],
  );

  const onItemCmdClick = useCallback(
    () =>
      openAssetInNewTab({
        asset: file,
        trackLocation: 'card-click',
      }),
    [file, openAssetInNewTab],
  );

  const onDiscussionsClick = useCallback(
    () =>
      goToAssetPage({
        asset: file,
        query: {
          [QueryParamNames.tab]: AssetModalPanel.COMMENTS,
        },
        trackLocation: 'comments-click',
      }),
    [file, goToAssetPage],
  );

  const onVersionsClick = useCallback(
    () =>
      goToAssetPage({
        asset: file,
        query: {
          [QueryParamNames.versions]: true,
        },
        trackLocation: 'versions-click',
      }),
    [file, goToAssetPage],
  );

  const canViewCustomFields = canSeeAssetCustomFields(clipPermissions);
  const canSeeDiscussions = canViewAssetDiscussions(clipPermissions);
  const canChangeCustomFields = canChangeAssetCustomFields(clipPermissions);

  return (
    <KanbanFileComponent
      cardHeight={cardHeight}
      file={file}
      getItemMenuOptions={_getItemMenuOptions}
      getSelectionMenuOptions={getSelectionMenuOptions}
      isSelectable={false}
      kanbanDndItemData={kanbanDndItemData}
      onCustomFieldsClick={onCustomFieldsClick}
      onFileClick={onItemClick}
      onFileTitleCmdClick={onItemCmdClick}
      style={style}
      dndDisabled={isMobileAgent || !canChangeCustomFields}
      canViewCustomFields={canViewCustomFields}
      allCustomFields={customFields}
      onDiscussionsClick={canSeeDiscussions ? onDiscussionsClick : undefined}
      onVersionsClick={!!file.assetVersionCount && file.assetVersionCount > 1 ? onVersionsClick : undefined}
    />
  );
}, isEqual);

PrivateKanbanFile.displayName = 'PrivateKanbanFile';
