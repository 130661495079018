import { WorkspaceInfo } from '@air/api/types';
import { ConfirmationModal } from '@air/component-confirmation-modal';
import { useState } from 'react';

import { SingleSelect } from '~/components/Select/SingleSelect';
import { WORKSPACE_SELECT_MODAL } from '~/constants/testIDs';

export interface WorkspaceSelectModalProps {
  workspaces: Pick<WorkspaceInfo, 'id' | 'name'>[];
  onSave: (workspace: Pick<WorkspaceInfo, 'id' | 'name'>) => void;
  isWorkspacesLoading: boolean;
  description: string;
}

export const WorkspaceSelectModal = ({
  onClose,
  workspaces,
  isWorkspacesLoading,
  description,
  onSave,
}: AirModalProps<WorkspaceSelectModalProps>) => {
  const [selectedWorkspace, setSelectedWorkspace] = useState<Pick<WorkspaceInfo, 'id' | 'name'> | undefined>(
    workspaces[0],
  );

  return (
    <ConfirmationModal
      id="workspace-select-modal"
      isOpen
      data-testid={WORKSPACE_SELECT_MODAL}
      onDismiss={onClose}
      description={description}
      ctaPrimaryText="Save"
      onConfirm={() => {
        if (selectedWorkspace) {
          onSave(selectedWorkspace);
        }
      }}
      isDisabled={!selectedWorkspace}
      title="Select workspace"
    >
      <div className="mt-4">
        <SingleSelect
          size="extra-large"
          options={workspaces.map(({ name, id }) => ({
            label: name,
            value: id,
          }))}
          onSelectionChange={({ chips }) => {
            const selected = chips[0];

            setSelectedWorkspace({
              id: selected.value,
              name: selected.label,
            });
          }}
          maxDropdownHeight={300}
          selectedOption={
            selectedWorkspace
              ? {
                  label: selectedWorkspace.name,
                  value: selectedWorkspace.id,
                }
              : undefined
          }
          isLoading={isWorkspacesLoading}
        />
      </div>
    </ConfirmationModal>
  );
};
