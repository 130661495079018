import { reportErrorToBugsnag } from '@air/utils-error';
import { noop } from 'lodash';
import { createContext, memo, PropsWithChildren, useCallback, useContext, useMemo, useRef, useState } from 'react';

import { isDevOrTestStage } from '~/swr-hooks/utils';

interface KanbanFetchPageContextType {
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;

  setHasNextPage: (hasNextPage: boolean) => void;
  setIsFetchingNextPage: (isFetchingNextPage: boolean) => void;
  setFetchNextPage: (fetchNextPage: () => void) => void;
}

const defaultKanbanFetchPageContextValue: KanbanFetchPageContextType = {
  hasNextPage: false,
  isFetchingNextPage: false,
  fetchNextPage: noop,
  setFetchNextPage: noop,
  setIsFetchingNextPage: noop,
  setHasNextPage: noop,
};

export const KanbanFetchPageContext = createContext<KanbanFetchPageContextType>(defaultKanbanFetchPageContextValue);

export const KanbanFetchPageProvider = memo(({ children }: PropsWithChildren<object>) => {
  const [hasNextPage, setHasNextPage] = useState(false);
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);

  const fetchNextPageRef = useRef(noop);

  const fetchNextPage = useCallback(() => {
    fetchNextPageRef.current();
  }, []);

  const setFetchNextPage = useCallback((fetchNextPage: () => void) => {
    fetchNextPageRef.current = fetchNextPage;
  }, []);

  const value = useMemo<KanbanFetchPageContextType>(
    () => ({
      hasNextPage,
      isFetchingNextPage,
      fetchNextPage,
      setFetchNextPage,
      setIsFetchingNextPage,
      setHasNextPage,
    }),
    [hasNextPage, isFetchingNextPage, fetchNextPage, setFetchNextPage],
  );

  return <KanbanFetchPageContext.Provider value={value}>{children}</KanbanFetchPageContext.Provider>;
});

KanbanFetchPageProvider.displayName = 'KanbanFetchPageProvider';

export const useKanbanFetchPageContext = () => {
  const context = useContext(KanbanFetchPageContext);

  if (context === defaultKanbanFetchPageContextValue) {
    const error = 'KanbanFetchPageContext used outside of KanbanFetchPageProvider';
    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
};
