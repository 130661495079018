import { BoardAncestors } from '@air/api/types';
import { memo } from 'react';

export interface PublicGalleryBoardAncestorsProps {
  ancestors: BoardAncestors;
}

export const PublicGalleryBoardAncestors = memo(({ ancestors }: PublicGalleryBoardAncestorsProps) => {
  const nearestAncestorTitle = ancestors[ancestors.length - 1].title;

  return <>{ancestors.length > 1 ? `··· / ${nearestAncestorTitle}` : nearestAncestorTitle}</>;
});

PublicGalleryBoardAncestors.displayName = 'PublicGalleryBoardAncestors';
