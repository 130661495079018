import { PublicBoard } from '@air/api/types';
import { memo, useCallback } from 'react';
import isEqual from 'react-fast-compare';

import { useGetPublicBoardMenuOptionsCallback } from '~/components/PublicBoard/useGetPublicBoardMenuOptionsCallback';
import { PublicGalleryBoardAncestors } from '~/components/PublicGallery/PublicGalleryBoardAncestors';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { useIsSearchActive } from '~/hooks/search/useIsSearchActive';
import { usePublicSelectionMenuOptions } from '~/hooks/usePublicSelectionMenuOptions';
import { useGoToPublicBoard } from '~/swr-hooks/boards/useGoToPublicBoard';
import { canChangeBoardCustomFields, canSeeBoardCustomFields } from '~/utils/permissions/boardPermissions';
import { canGenerateZip } from '~/utils/permissions/taskPermissions';
import { isMobileAgent } from '~/utils/PlatformHelpers';

import { KanbanBoardComponent } from './shared/KanbanBoardComponent';
import { KanbanBoardProps } from './shared/kanbanItemTypes';

export const PublicKanbanBoard = memo(({ board, kanbanDndItemData, style }: KanbanBoardProps<PublicBoard>) => {
  const { isSearchActive } = useIsSearchActive();
  const { permissions } = usePublicPermissions();
  const { getSelectionMenuOptions } = usePublicSelectionMenuOptions();
  const { getBoardMenuOptionsCallback } = useGetPublicBoardMenuOptionsCallback();
  const _getItemMenuOptions = getBoardMenuOptionsCallback({ item: board });
  const { goToPublicBoard } = useGoToPublicBoard();
  const onItemClick = useCallback(() => goToPublicBoard(board, 'board'), [board, goToPublicBoard]);

  const canDownload = canGenerateZip(permissions);
  const canEditCustomFields = canChangeBoardCustomFields(permissions);
  const canViewCustomFields = canSeeBoardCustomFields(permissions);

  const isSelectable = canDownload || canEditCustomFields;

  return (
    <KanbanBoardComponent
      board={board}
      dndDisabled={isMobileAgent || !canEditCustomFields}
      getItemMenuOptions={_getItemMenuOptions}
      getSelectionMenuOptions={getSelectionMenuOptions}
      kanbanDndItemData={kanbanDndItemData}
      onClick={onItemClick}
      isSelectable={isSelectable}
      ancestors={
        isSearchActive && board.ancestors ? <PublicGalleryBoardAncestors ancestors={board.ancestors} /> : undefined
      }
      style={style}
      canViewCustomFields={canViewCustomFields}
    />
  );
}, isEqual);

PublicKanbanBoard.displayName = 'PublicKanbanBoard';
