import { AirActionTrackingLocation, useTrackDownloadedItems } from '@air/analytics';
import { useCallback } from 'react';

import { useCreatePublicZippingTask } from '~/components/ZippingTasks/hooks/useCreatePublicZippingTask';

interface DownloadPublicItemsParams {
  /** Ids of the board who's assets to download */
  boardIds?: string[];
  /** Ids of the clips to download */
  clipIds?: string[];
  /** Where in the UI this was called from */
  trackLocation: AirActionTrackingLocation;
}

/**
 * This hook returns a function that allows a user to download multiple public assets and or boards
 */
export function useDownloadPublicItems() {
  const { trackDownloadedItems } = useTrackDownloadedItems();
  const { createPublicZippingTask } = useCreatePublicZippingTask();

  const downloadPublicItems = useCallback(
    async ({ trackLocation, clipIds, boardIds }: DownloadPublicItemsParams) => {
      createPublicZippingTask({ boardIds, clipIds });

      trackDownloadedItems({
        boardIds,
        clipIds,
        location: trackLocation,
      });
    },
    [createPublicZippingTask, trackDownloadedItems],
  );

  return {
    downloadPublicItems,
  };
}
