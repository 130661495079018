import { ShortUrl } from '@air/api';
import { PublicBoard } from '@air/api/types';
import { useShortIdContext } from '@air/provider-short-id';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { setCentralizedPublicBoardAction } from '~/store/centralizedBoard/actions';

export type PublicBoardPage = PublicBoard & Required<Pick<PublicBoard, 'thumbnails' | 'ancestors'>>;

const PUBLIC_BOARD_KEY = 'PUBLIC_BOARD';

export const getPublicBoardPageKey = ({ shortId, boardId }: { shortId: string; boardId: string }) => [
  PUBLIC_BOARD_KEY,
  { shortId, boardId },
];

export const usePublicBoardPage = ({
  boardId,
  initialData,
}: {
  boardId: string;
  initialData: (PublicBoard & Required<Pick<PublicBoard, 'thumbnails' | 'ancestors'>>) | undefined;
}) => {
  const dispatch = useDispatch();
  const { shortId } = useShortIdContext();

  return useQuery({
    queryKey: getPublicBoardPageKey({ shortId, boardId }),
    queryFn: async () => {
      const { board } = await ShortUrl.getDescendantBoard(shortId, boardId);

      dispatch(setCentralizedPublicBoardAction({ board }));

      return board;
    },
    /**
     * When we inject boards into the cache with getPublicBoardPageKey,
     * no matter how long ago it was, we want to always refetch
     * for the latest board information
     */
    staleTime: 0,
    initialData,
  });
};
