import { ReactNode, RefObject, useCallback, useEffect, useMemo } from 'react';

import { FlattenedBoardSearchToken } from '~/components/Search/SearchTokens/FlattenedBoardSearchToken';
import { useSearchTokenLogic } from '~/components/Search/SearchTokens/useSearchTokenLogic';
import { SearchBarHandle } from '~/components/SearchBar/SearchBar';
import { SearchToken } from '~/components/SearchBar/SearchToken';
import { SEARCH_BOARD_TOKEN } from '~/constants/testIDs';
import { useIsFlattenedView } from '~/hooks/search/useIsFlattenedView';

export interface UseSearchBoardTokenParams {
  isFocused: boolean;
  hasValue: boolean;
  boardName?: string;
  onSearchTokenToggle?: (hasBoardToken: boolean) => void;
  searchBarRef: RefObject<SearchBarHandle>;
}

export interface UseSearchBoardTokenResult {
  onBackspacePress: () => void;
  BoardToken: ReactNode;
  wasTokenRemoved: boolean;
  showToken: () => void;
}

export const useBoardSearchToken = ({
  hasValue,
  isFocused,
  boardName,
  onSearchTokenToggle,
  searchBarRef,
}: UseSearchBoardTokenParams): UseSearchBoardTokenResult => {
  const { isFlattenedView, setIsFlattenedView } = useIsFlattenedView();

  const { showToken, isTokenVisible, wasTokenRemoved, hideToken } = useSearchTokenLogic({ hasValue, isFocused });

  useEffect(() => {
    onSearchTokenToggle?.(!wasTokenRemoved);
  }, [wasTokenRemoved, onSearchTokenToggle]);

  const onBackspacePress = useCallback(() => {
    if (!wasTokenRemoved) {
      isFlattenedView ? setIsFlattenedView(false) : hideToken();
    }
  }, [hideToken, isFlattenedView, setIsFlattenedView, wasTokenRemoved]);

  const BoardToken = useMemo(() => {
    if (isFlattenedView) {
      return <FlattenedBoardSearchToken boardName={boardName} />;
    } else if (isTokenVisible && !!boardName) {
      return (
        <SearchToken
          testId={SEARCH_BOARD_TOKEN}
          tokenName={boardName}
          color="grey"
          onCloseClick={() => {
            if (!isFocused) {
              searchBarRef?.current?.focus();
            }
            hideToken();
          }}
        />
      );
    } else {
      return null;
    }
  }, [boardName, hideToken, isFlattenedView, isFocused, isTokenVisible, searchBarRef]);

  return {
    BoardToken,
    onBackspacePress,
    wasTokenRemoved,
    showToken,
  };
};
