import { BaseCustomField } from '@air/api/types';

export const useGetSingleSelectCustomFields = (data?: BaseCustomField[]) => {
  const singleSelectCustomFields = data?.reduce((acc, curr) => {
    if (curr.type.title === 'single-select') {
      acc.push(curr);
    }
    return acc;
  }, [] as BaseCustomField[]);

  const hasSingleSelectCustomFields = !!singleSelectCustomFields && singleSelectCustomFields.length > 0;
  return { hasSingleSelectCustomFields };
};
