import { PublicBoard } from '@air/api/types';
import { useAirModal } from '@air/provider-modal';
import { useShortIdContext } from '@air/provider-short-id';
import { useCallback } from 'react';

import { SharePublicBoardModal } from '~/components/Modals/SharePublicBoardModal';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { GetItemMenuOptionsCallback } from '~/constants/MenuOptions';
import { useDownloadPublicBoard } from '~/hooks/useDownloadPublicBoard';
import { useGetPublicCustomFieldsMenuOptions } from '~/hooks/useGetCustomFieldsMenuOptions';
import { useGetPublicBoardMenuOptions } from '~/hooks/useGetPublicBoardMenuOptions';
import { getPublicSubboardShareUrl } from '~/swr-hooks/shortUrl/utils';
import { canChangeBoardCustomFields } from '~/utils/permissions/boardPermissions';
import { canGenerateZip } from '~/utils/permissions/taskPermissions';

export function useGetPublicBoardMenuOptionsCallback() {
  const { downloadPublicBoard } = useDownloadPublicBoard();
  const [showSharePublicBoardModal] = useAirModal(SharePublicBoardModal);
  const { permissions } = usePublicPermissions();
  const { getCustomFieldsMenuOptions } = useGetPublicCustomFieldsMenuOptions();
  const { shortId } = useShortIdContext();
  const { getBoardMenuOptions } = useGetPublicBoardMenuOptions();

  const getBoardMenuOptionsCallback: GetItemMenuOptionsCallback<PublicBoard> = useCallback(
    ({ item: board }) =>
      ({ trackLocation }) => {
        const canDownload = canGenerateZip(permissions);
        const canEditCustomFields = canChangeBoardCustomFields(permissions);

        return getBoardMenuOptions({
          onDownload: canDownload
            ? () => {
                downloadPublicBoard({
                  boardId: board.id,
                  trackLocation,
                });
              }
            : undefined,
          onShare: () => {
            showSharePublicBoardModal({
              id: board.id,
              url: getPublicSubboardShareUrl(shortId, board.id),
              trackLocation,
            });
          },
          customFieldsMenuOptions: canEditCustomFields ? getCustomFieldsMenuOptions({ boards: [board] }) : undefined,
        });
      },
    [
      permissions,
      downloadPublicBoard,
      getBoardMenuOptions,
      getCustomFieldsMenuOptions,
      shortId,
      showSharePublicBoardModal,
    ],
  );

  return {
    getBoardMenuOptionsCallback,
  };
}
