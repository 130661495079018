import { Board, Clip } from '@air/api/types';
import { useCallback } from 'react';

import { DNDAssetGalleryCardDragPreview } from '~/components/DndAssetDragPreview';
import { DNDBoardGalleryCardDragPreview } from '~/components/DndBoardDragPreview';
import { DndDragPreviewContainer } from '~/components/DndDragPreviewContainer';
import { DNDFileGalleryCardDragPreview } from '~/components/DNDFileGalleryCardDragPreview';
import { DndItemType, DndSortableKanbanItemData } from '~/types/DndKit';

interface KanbanItemsDragPreviewProps {
  itemData: DndSortableKanbanItemData;
}

/**
 * This component should later match GalleryItemsDragPreview
 * Just add `mixedTypes` and `count` accordingly
 */
export const KanbanItemsDragPreview = ({ itemData }: KanbanItemsDragPreviewProps) => {
  const renderContent = useCallback(() => {
    const data = itemData.apiData.data;
    switch (itemData.dndType) {
      case DndItemType.kanbanBoard:
        return <DNDBoardGalleryCardDragPreview board={data as Board} />;
      case DndItemType.kanbanAsset:
        return <DNDAssetGalleryCardDragPreview asset={data as Clip} />;
      case DndItemType.kanbanFile:
        return <DNDFileGalleryCardDragPreview file={data as Clip} />;
    }
  }, [itemData]);

  return (
    <div className="relative">
      <DndDragPreviewContainer className="absolute left-0 top-0 flex">{renderContent()}</DndDragPreviewContainer>
    </div>
  );
};
