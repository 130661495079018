import type { Modifier } from '@dnd-kit/core';
import { getEventCoordinates } from '@dnd-kit/utilities';

/**
 * This is an estimated snap, not a real one
 */
export const snapToKanbanColumnHandler: Modifier = ({ activatorEvent, draggingNodeRect, transform }) => {
  if (draggingNodeRect && activatorEvent) {
    const activatorCoordinates = getEventCoordinates(activatorEvent);
    if (!activatorCoordinates) {
      return transform;
    }

    const offsetX = activatorCoordinates.x - draggingNodeRect.left - draggingNodeRect.width + 16;

    return {
      ...transform,
      x: transform.x + offsetX,
    };
  }

  return transform;
};
