import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentPropsWithoutRef, forwardRef } from 'react';

type InfiniteScrollContainerProps = ComponentPropsWithoutRef<'div'>;

export const InfiniteScrollContainer = forwardRef<HTMLDivElement, InfiniteScrollContainerProps>(
  ({ className, ...props }: InfiniteScrollContainerProps, ref) => {
    return (
      <div ref={ref} className={tailwindMerge('h-100 flex w-full items-center justify-center', className)} {...props} />
    );
  },
);
InfiniteScrollContainer.displayName = 'InfiniteScrollContainer';
