import { ShortUrl } from '@air/api';
import { useShortIdContext } from '@air/provider-short-id';
import { useCallback } from 'react';

import { GetBaseMetadata, ZippingTask } from '~/store/tasks/types';
import { useCreateTask } from '~/utils/taskUtils/useCreateTask';

export type CreatePublicZippingTaskParams = GetBaseMetadata<ZippingTask>;

export const useCreatePublicZippingTask = () => {
  const { createTask } = useCreateTask();
  const { shortId } = useShortIdContext();

  const createPublicZippingTask = useCallback(
    async ({ boardIds = [], clipIds = [] }: CreatePublicZippingTaskParams) => {
      return createTask({
        task: {
          type: 'ZippingForDownload',
          shortId,
          metadata: {
            boardIds,
            clipIds,
            secondsRemaining: undefined,
          },
        },
        createTask: () => ShortUrl.Tasks.createTask(shortId, { type: 'ContentExporter', args: { boardIds, clipIds } }),
      });
    },
    [createTask, shortId],
  );

  return {
    createPublicZippingTask,
  };
};
