import { Gizmo, Minus } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';
import { useSortable } from '@dnd-kit/sortable';

import { KanbanColumnHeaderDisplay } from '~/components/KanbanColumnHeaderDisplay';
import { KANBAN_COL_PX } from '~/components/KanbanView/shared/kanbanConstants';
import { KANBAN_COLUMN_HEADER } from '~/constants/testIDs';

export interface KanbanColumnHeaderProps {
  onHideColumn?: () => void;
  itemCount: number | undefined;
  label: string;
  id: string;
  color?: string;
  dndListeners?: Partial<Pick<ReturnType<typeof useSortable>, 'listeners'>>;
}

export const KanbanColumnHeader = ({
  onHideColumn,
  itemCount,
  label,
  id,
  color,
  dndListeners,
}: KanbanColumnHeaderProps) => {
  return (
    <div
      className="flex items-center justify-between gap-2"
      data-testid={KANBAN_COLUMN_HEADER}
      style={{
        paddingTop: KANBAN_COL_PX - 6,
        paddingBottom: KANBAN_COL_PX - 6,
        paddingLeft: KANBAN_COL_PX,
        paddingRight: KANBAN_COL_PX,
      }}
    >
      <KanbanColumnHeaderDisplay id={id} label={label} color={color} itemCount={itemCount} />
      <div className="flex items-center gap-2">
        {onHideColumn && (
          <Tooltip label="Hide this column" side="top">
            <IconButton
              appearance="ghost"
              color="grey"
              icon={Minus}
              label="Hide column"
              size="small"
              onClick={onHideColumn}
            />
          </Tooltip>
        )}
        {dndListeners && (
          <IconButton
            appearance="ghost"
            className="cursor-grab active:cursor-grabbing"
            color="grey"
            icon={Gizmo}
            label="Move"
            size="small"
            {...dndListeners}
          />
        )}
      </div>
    </div>
  );
};
