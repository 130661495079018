import { Spinner } from '@air/primitive-spinner';
import { memo, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

interface KanbanColumnLoadMoreItemsProps {
  isLoading: boolean;
  loadMore: () => void;
}

export const KanbanColumnLoadMoreItems = memo(({ loadMore, isLoading }: KanbanColumnLoadMoreItemsProps) => {
  const { ref, inView } = useInView({
    threshold: 0.01,
    rootMargin: '300px',
  });

  const isLoadingRef = useRef(isLoading);
  isLoadingRef.current = isLoading;

  useEffect(() => {
    if (inView && !isLoadingRef.current) {
      loadMore();
    }
  }, [inView, loadMore]);

  return (
    <div ref={ref} className="flex h-[150px] items-center justify-center py-1.5">
      <Spinner className="h-8 w-8 text-teal-9" />
    </div>
  );
});

KanbanColumnLoadMoreItems.displayName = 'KanbanColumnLoadMoreItems';
