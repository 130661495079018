import { useDndContext, useDroppable } from '@dnd-kit/core';
import { memo, ReactNode, useMemo } from 'react';

import { KanbanColumnContainer } from '~/components/KanbanView/shared/KanbanColumnContainer';
import {
  DndDroppableKanbanColumnData,
  DndItemType,
  isDndDroppableKanbanColumnData,
  isDndSortableKanbanColumnData,
  isDndSortableKanbanItemData,
} from '~/types/DndKit';
import { getDroppableKanbanColumnKey } from '~/utils/getDndKeys';
interface DndDroppableKanbanColumnProps {
  children?: ReactNode;
  header?: ReactNode;
  isLoading?: boolean;
  kanbanColumnId: string;
}
/** This component allows us to recognize the movement from column to column */
export const DndDroppableKanbanColumn = memo(
  ({ children, header, isLoading, kanbanColumnId }: DndDroppableKanbanColumnProps) => {
    const { active, over } = useDndContext();
    const activeData = active?.data.current;
    const overData = over?.data.current;
    const variant = useMemo(() => {
      if (isDndSortableKanbanItemData(activeData)) {
        if (isDndSortableKanbanItemData(overData) && overData.currentKanbanColumnId === kanbanColumnId) {
          return 'activeDropzoneWithChildren';
        } else if (
          isDndDroppableKanbanColumnData(overData) &&
          overData.kanbanColumnId === kanbanColumnId &&
          activeData.currentKanbanColumnId !== kanbanColumnId
        ) {
          return 'activeDropzone';
        }
      }

      if (isDndSortableKanbanColumnData(activeData) && activeData.kanbanColumnId === kanbanColumnId) {
        return 'withDragOverlay';
      }
      return undefined;
    }, [kanbanColumnId, activeData, overData]);
    const data: DndDroppableKanbanColumnData = useMemo(
      () => ({
        dndType: DndItemType.kanbanDroppableColumn,
        kanbanColumnId,
      }),
      [kanbanColumnId],
    );
    const { setNodeRef } = useDroppable({ id: getDroppableKanbanColumnKey(kanbanColumnId), data });
    return (
      <KanbanColumnContainer header={header} isLoading={isLoading} variant={variant} ref={setNodeRef}>
        {children}
      </KanbanColumnContainer>
    );
  },
);

DndDroppableKanbanColumn.displayName = 'DndDroppableKanbanColumn';
