import { CSSProperties, memo } from 'react';

import {
  UploadingAssetGalleryCard,
  UploadingAssetGalleryCardProps,
} from '~/components/Gallery/UploadingAssetGalleryCard';
import { KANBAN_ASSET_CARD_HEIGHT, KANBAN_ITEM_CARD_WIDTH } from '~/components/KanbanView/shared/kanbanConstants';
import { DndSortableKanbanItemData } from '~/types/DndKit';

import { DndSortableKanbanItem } from './DndSortableKanbanItem';

interface KanbanAssetComponentProps extends Omit<UploadingAssetGalleryCardProps, 'width'> {
  kanbanDndItemData: DndSortableKanbanItemData;
  style: CSSProperties;
}

const _KanbanUploadingAssetComponent = ({ kanbanDndItemData, style, upload }: KanbanAssetComponentProps) => {
  return (
    <DndSortableKanbanItem
      style={style}
      id={kanbanDndItemData.sortableKanbanItemKey}
      data={kanbanDndItemData}
      dndDisabled={true}
    >
      <div className="relative" style={{ height: KANBAN_ASSET_CARD_HEIGHT }}>
        <UploadingAssetGalleryCard
          width={KANBAN_ITEM_CARD_WIDTH}
          getClassName={() =>
            'bg-white shadow-md cursor-pointer shadow-[0_1px_8px_rgba(0,0,0,0.1),0_0_2px_rgba(0,0,0,0.15)]'
          }
          upload={upload}
        />
      </div>
    </DndSortableKanbanItem>
  );
};

export const KanbanUploadingAssetComponent = memo(
  _KanbanUploadingAssetComponent,
) as typeof _KanbanUploadingAssetComponent;
