import { useTrackCreatedWorkspace } from '@air/analytics';
import { Workspaces } from '@air/api';
import { WorkspaceListResponse } from '@air/api/types';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { getWorkspacesListKey } from './useWorkspaces';

export const useCreateWorkspace = () => {
  const queryClient = useQueryClient();
  const { trackCreatedWorkspace } = useTrackCreatedWorkspace();

  const createWorkspace = useCallback(
    async (workspaceName: string, email: string) => {
      const workspace = await Workspaces.create({
        workspace: { name: workspaceName, status: 'active' },
      });

      queryClient.setQueryData(getWorkspacesListKey(), (workspaces?: WorkspaceListResponse) =>
        workspaces ? [workspace, ...workspaces] : [workspace],
      );

      trackCreatedWorkspace({ ...workspace, creatorId: email });

      return workspace;
    },
    [queryClient, trackCreatedWorkspace],
  );

  return {
    createWorkspace,
  };
};
