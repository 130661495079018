import { VisibleColumnType } from '@air/api/types';
import { memo } from 'react';

import { KanbanFetchPageProvider } from '~/components/KanbanView/KanbanColumnsManagers/shared/KanbanFetchPageContext';
import { useKanbanContext } from '~/components/KanbanView/Providers/KanbanProvider';
import { KanbanColumnContainer } from '~/components/KanbanView/shared/KanbanColumnContainer';
import { KANBAN_COL_GUTTER } from '~/components/KanbanView/shared/kanbanConstants';
import { kanbanColumnItemsSelector } from '~/store/kanbanManager/selectors';
import { DndSortableKanbanColumnData } from '~/types/DndKit';
import { useAirSelector } from '~/utils/ReduxUtils';

import { KanbanColumnHeader, KanbanColumnHeaderProps } from '../shared/KanbanColumnHeader';
import { KanbanColumnItems } from '../shared/KanbanColumnItems/KanbanColumnItems';

interface DndDragKanbanColumnProps {
  draggedColumnData: DndSortableKanbanColumnData;
}
export const DndDragKanbanColumn = memo(({ draggedColumnData }: DndDragKanbanColumnProps) => {
  const kanbanItems = useAirSelector((state) =>
    kanbanColumnItemsSelector(state, draggedColumnData?.kanbanColumnId || ''),
  );
  const { customFieldValues } = useKanbanContext();

  const headerProps: KanbanColumnHeaderProps = {
    itemCount: kanbanItems.length,
    id: `${draggedColumnData.kanbanColumnId}-dragging`,
    label: 'None',
    color: 'transparent',
  };
  if (draggedColumnData.kanbanColumnId !== VisibleColumnType.unassignedCustomFieldValue) {
    const cfValue = customFieldValues?.find((cfVal) => cfVal.id === draggedColumnData.kanbanColumnId);
    if (cfValue) {
      headerProps.label = cfValue.value;
      headerProps.color = cfValue.color.name;
      headerProps.itemCount = kanbanItems.length;
    }
  }
  return (
    <div
      className="pointer-events-none h-[70vh] origin-top scale-[0.6] px-[KANBAN_COL_GUTTER/2]"
      style={{
        paddingLeft: KANBAN_COL_GUTTER / 2,
        paddingRight: KANBAN_COL_GUTTER / 2,
      }}
    >
      <KanbanFetchPageProvider>
        <KanbanColumnContainer
          header={
            <div className="mb-4 max-w-[60%] origin-top-left scale-[1.6]">
              <KanbanColumnHeader {...headerProps} />
            </div>
          }
        >
          <KanbanColumnItems kanbanColumnId={draggedColumnData.kanbanColumnId} />
        </KanbanColumnContainer>
      </KanbanFetchPageProvider>
    </div>
  );
});

DndDragKanbanColumn.displayName = 'DndDragKanbanColumn';
