import { Close as CloseIcon } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { Portal } from '@air/primitive-portal';
import { memo, ReactNode } from 'react';

export const CLOSE_BOARD_CUSTOM_FIELD_PANEL_BUTTON = 'CLOSE_BOARD_CUSTOM_FIELD_PANEL_BUTTON';

export type CustomFieldsPanelProps = {
  children: ReactNode;
  onClose: () => void;
  breadcrumbs?: ReactNode;
  footer?: ReactNode;
  title?: string;
};

export const CustomFieldsPanel = memo(({ children, breadcrumbs, footer, onClose, title }: CustomFieldsPanelProps) => {
  return (
    <Portal>
      <>
        <div className="fixed inset-0 bg-black/60" data-testid="CUSTOM_FIELDS_PANEL_OVERLAY" onClick={onClose} />
        <div
          className="fixed inset-y-0 right-0 flex w-[396px] flex-col overflow-hidden bg-surface-1"
          data-disableselect="true"
          data-testid="CUSTOM_FIELDS_PANEL"
        >
          <header className="flex shrink-0 flex-col gap-2 border-b border-b-grey-5 px-6 py-4">
            {!!title && <h1 className="order-2 text-20 font-semibold text-grey-12">{title}</h1>}
            <div className="order-1 flex items-center justify-between">
              <div>{breadcrumbs}</div>
              <IconButton
                appearance="ghost"
                color="grey"
                className="shrink-0"
                data-testid={CLOSE_BOARD_CUSTOM_FIELD_PANEL_BUTTON}
                icon={CloseIcon}
                label="Close"
                onClick={onClose}
              />
            </div>
          </header>

          <div className="h-screen grow overflow-auto p-6">{children}</div>

          {!!footer && <footer className="shrink-0 border-t border-t-grey-5 px-6 py-4">{footer}</footer>}
        </div>
      </>
    </Portal>
  );
});

CustomFieldsPanel.displayName = 'CustomFieldsPanel';
