import { Fields } from '@air/next-icons';
import { IconButton, type IconButtonProps } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';
import { memo } from 'react';

export type EditCustomFieldsButtonProps = Pick<IconButtonProps, 'appearance' | 'color' | 'onClick' | 'size'>;

export const EditCustomFieldsButton = memo(
  ({ appearance = 'ghost', color = 'grey', onClick, size = 'medium' }: EditCustomFieldsButtonProps) => {
    const label = 'Add custom fields to board';

    return (
      <Tooltip label={label}>
        <IconButton
          appearance={appearance}
          color={color}
          data-testid="EDIT_CUSTOM_FIELDS_BUTTON"
          icon={Fields}
          label={label}
          onClick={onClick}
          size={size}
        />
      </Tooltip>
    );
  },
);

EditCustomFieldsButton.displayName = 'EditCustomFieldsButton';
