import { memo } from 'react';

import { ContentHeaderDescriptionContainer } from '~/components/PageHeader/ContentHeaderDescriptionContainer';
import { EditableContentHeaderDescription } from '~/components/PageHeader/EditableContentHeaderDescription';
import { BOARD_BLURB_DESCRIPTION } from '~/constants/testIDs';

interface DescriptionProps {
  description: string;
  onSubmit?: (description: string) => void;
  className?: string;
}

export const BoardHeaderDescription = memo(({ description, onSubmit, className }: DescriptionProps) => {
  return (
    <ContentHeaderDescriptionContainer className={className}>
      <EditableContentHeaderDescription
        label="Board description"
        text={description}
        onSubmit={onSubmit}
        testId={BOARD_BLURB_DESCRIPTION}
      />
    </ContentHeaderDescriptionContainer>
  );
});

BoardHeaderDescription.displayName = 'BoardHeaderDescription';
