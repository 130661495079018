import { useBreakpointsContext } from '@air/provider-media-query';
import { useMemo } from 'react';

import BoardCrumbs from '~/components/Workspace/BoardHeader/BoardCrumbs';
import { BoardAncestorsDropdownProps } from '~/components/Workspace/BoardHeader/BoardCrumbs/BoardAncestorsDropdown';
import { ShortBoardCrumbs } from '~/components/Workspace/BoardHeader/BoardCrumbs/ShortBoardCrumbs';

interface RootBoardStickyHeaderProps extends BoardAncestorsDropdownProps {
  title: string;
}

export const ViewControlsBoardHeader = ({ title, ancestors = [], onBoardClick }: RootBoardStickyHeaderProps) => {
  const { isAboveXLScreen } = useBreakpointsContext();

  const ancestorsView = useMemo(() => {
    if (isAboveXLScreen) {
      if (!ancestors.length) {
        return <div className="truncate text-12 font-medium text-grey-11">{title}</div>;
      } else {
        return <BoardCrumbs ancestors={ancestors || []} title={title} onBoardClick={onBoardClick} />;
      }
    }
    return <ShortBoardCrumbs ancestors={ancestors} title={title} onBoardClick={onBoardClick} />;
  }, [ancestors, isAboveXLScreen, onBoardClick, title]);

  return <div className="flex">{ancestorsView}</div>;
};
