import { VisibleColumnType } from '@air/api/types';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { memo, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useKanbanContext } from '~/components/KanbanView/Providers/KanbanProvider';
import { hideKanbanColumnAction } from '~/store/configViews/actions';
import { currentKanbanGroupByCustomFieldIdSelector } from '~/store/configViews/selectors';
import {
  kanbanColumnItemCountSelector,
  kanbanColumnNeedsToLoadItemsSelector,
  kanbanColumnSortableKeysSelector,
} from '~/store/kanbanManager/selectors';
import { getSortableContextKanbanColumnItemsKey } from '~/utils/getDndKeys';
import { useAirSelector } from '~/utils/ReduxUtils';

import { KanbanColumnHeader, KanbanColumnHeaderProps } from '../../../shared/KanbanColumnHeader';
import { KanbanColumnItems } from '../../../shared/KanbanColumnItems/KanbanColumnItems';
import { DndDroppableKanbanColumn } from './DndDroppableKanbanColumn';

interface DndSortableContextKanbanColumnProps {
  kanbanColumnId: string;
  dndListeners?: Partial<Pick<ReturnType<typeof useSortable>, 'listeners'>>;
}
export const DndSortableContextKanbanColumn = memo(
  ({ kanbanColumnId, dndListeners }: DndSortableContextKanbanColumnProps) => {
    const { customFieldValues } = useKanbanContext();
    const kanbanItemsCount = useAirSelector((state) => kanbanColumnItemCountSelector(state, kanbanColumnId));
    const customFieldId = useAirSelector(currentKanbanGroupByCustomFieldIdSelector);
    const dispatch = useDispatch();
    const sortableKeys = useAirSelector((state) => kanbanColumnSortableKeysSelector(state, kanbanColumnId));
    const isLoading = useAirSelector((state) => kanbanColumnNeedsToLoadItemsSelector(state, kanbanColumnId));
    const colHeaderProps = useMemo(() => {
      const props: KanbanColumnHeaderProps = {
        onHideColumn: () => dispatch(hideKanbanColumnAction({ kanbanColumnId })),
        itemCount: kanbanItemsCount,
        id: `no-${customFieldId}`,
        label: 'None',
        color: 'transparent',
        dndListeners,
      };
      if (kanbanColumnId !== VisibleColumnType.unassignedCustomFieldValue) {
        const cfValue = customFieldValues?.find((cfVal) => cfVal.id === kanbanColumnId);
        if (cfValue) {
          props.id = cfValue.id;
          props.label = cfValue.value;
          props.color = cfValue.color.name;
          props.itemCount = kanbanItemsCount;
        }
      }
      return props;
    }, [dispatch, kanbanColumnId, customFieldId, customFieldValues, kanbanItemsCount, dndListeners]);
    if (!customFieldId) return null;

    return (
      <DndDroppableKanbanColumn
        isLoading={isLoading}
        kanbanColumnId={kanbanColumnId}
        header={<KanbanColumnHeader {...colHeaderProps} />}
      >
        <SortableContext
          id={getSortableContextKanbanColumnItemsKey(kanbanColumnId)}
          items={sortableKeys}
          strategy={verticalListSortingStrategy}
        >
          <KanbanColumnItems kanbanColumnId={kanbanColumnId} />
        </SortableContext>
      </DndDroppableKanbanColumn>
    );
  },
);

DndSortableContextKanbanColumn.displayName = 'DndSortableContextKanbanColumn';
