import { type DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { useCallback } from 'react';

import { getDropdownMenuOption } from '~/utils/menuOptions/getDropdownMenuOption';

export interface UseGetPublicClipMenuOptionsParams {
  customFieldsMenuOptions?: DropdownMenuOption[];
  /** Callback for when the user selects Copy to Air workspace */
  onCopyToAir?: () => void;
  /** Callback for when the user selects to download this clip */
  onDownload?: () => void;
  /** Callback for when the user selects to open asset details modal */
  onOpen?: () => void;
  /** Callback for when the user selects to share this clip */
  onShare?: () => void;
}

/**
 * Use this utility to build out the order of the options you'd see in an ellipsis menu for a board.
 * Just pass the functions you want, with the board (and it's permissions).
 */
export const useGetPublicClipMenuOptions = () => {
  const getClipMenuOptions = useCallback(
    ({ onOpen, onShare, onDownload, onCopyToAir, customFieldsMenuOptions }: UseGetPublicClipMenuOptionsParams) => {
      const options: DropdownMenuOption[] = [];

      onOpen &&
        options.push({
          ...getDropdownMenuOption('open-asset'),
          onSelect: onOpen,
          type: 'item',
        });

      onShare &&
        options.push({
          ...getDropdownMenuOption('share'),
          onSelect: onShare,
          type: 'item',
        });

      onCopyToAir &&
        options.push({
          ...getDropdownMenuOption('copy-to-air'),
          onSelect: onCopyToAir,
          type: 'item',
        });

      onDownload &&
        options.push({
          ...getDropdownMenuOption('download'),
          onSelect: onDownload,
          type: 'item',
        });

      customFieldsMenuOptions &&
        customFieldsMenuOptions.length > 0 &&
        options.push({
          ...getDropdownMenuOption('edit-custom-fields'),
          options: customFieldsMenuOptions,
          type: 'sub',
        });

      return options;
    },
    [],
  );

  return { getClipMenuOptions };
};
