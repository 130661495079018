import { uploadByIdSelector } from '@air/redux-uploader';
import { useDndContext } from '@dnd-kit/core';
import { useCallback } from 'react';
import { ListChildComponentProps } from 'react-window';

import { KanbanColumnLoadMoreItems } from '~/components/KanbanView/KanbanColumnsManagers/shared/KanbanColumnItems/KanbanColumnLoadMoreItems';
import {
  KANBAN_ASSET_CARD_HEIGHT,
  KANBAN_BOARD_CARD_HEIGHT,
  KANBAN_FILE_CARD_HEIGHT,
  KANBAN_ITEM_DROP_PLACEHOLDER_HEIGHT,
  KANBAN_VIRTUALIZED_CARD_GUTTER,
} from '~/components/KanbanView/shared/kanbanConstants';
import { ASSET_CARD_ROW_MT } from '~/constants/assetCard';
import { WKSPC_CUSTOM_FIELD_DISPLAY_HEIGHT } from '~/constants/WorkspaceSpacing';
import { visibleCustomFieldsLengthSelector } from '~/store/configViews/selectors';
import { kanbanColumnItemsSelector } from '~/store/kanbanManager/selectors';
import { DndItemType, DndSortableKanbanItemData, isDndSortableKanbanItemData } from '~/types/DndKit';
import { useAirStore } from '~/utils/ReduxUtils';

import { KanbanItem } from './KanbanItem/KanbanItem';

type UseRenderKanbanItemParams = {
  kanbanColumnId: string;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
};

export const useRenderKanbanItem = ({
  kanbanColumnId,
  isFetchingNextPage,
  fetchNextPage,
}: UseRenderKanbanItemParams) => {
  const { active, over } = useDndContext();
  const activeData = active?.data.current;
  const overData = over?.data.current;
  const { getState } = useAirStore();

  const getInitialItemCardHeight = useCallback(
    (itemData: DndSortableKanbanItemData) => {
      let size = 0;
      const visibleCustomFieldsLength = visibleCustomFieldsLengthSelector(getState());

      switch (itemData.dndType) {
        case DndItemType.kanbanBoard:
          size = KANBAN_BOARD_CARD_HEIGHT;
          break;
        case DndItemType.kanbanAsset: {
          const addedMargin = visibleCustomFieldsLength ? ASSET_CARD_ROW_MT : 0;
          size = KANBAN_ASSET_CARD_HEIGHT + addedMargin + visibleCustomFieldsLength * WKSPC_CUSTOM_FIELD_DISPLAY_HEIGHT;
          break;
        }
        case DndItemType.kanbanFile: {
          const additionalRows = Math.max(0, visibleCustomFieldsLength - 2);
          size = KANBAN_FILE_CARD_HEIGHT + additionalRows * WKSPC_CUSTOM_FIELD_DISPLAY_HEIGHT;
          break;
        }
        case DndItemType.kanbanUpload: {
          const apiData = uploadByIdSelector(getState(), itemData.itemId);
          if (apiData?.isNonMedia) {
            size = KANBAN_FILE_CARD_HEIGHT;
          } else {
            size = KANBAN_ASSET_CARD_HEIGHT;
          }
          break;
        }
      }
      return size;
    },
    [getState],
  );

  const renderKanbanItem = useCallback(
    ({ index, style }: ListChildComponentProps) => {
      const kanbanDndItems = kanbanColumnItemsSelector(getState(), kanbanColumnId);

      if (index === kanbanDndItems.length) {
        return (
          <div style={style}>
            <KanbanColumnLoadMoreItems isLoading={isFetchingNextPage} loadMore={fetchNextPage} />
          </div>
        );
      }

      const kanbanDndItem: DndSortableKanbanItemData | null = kanbanDndItems[index] || null;
      if (!kanbanDndItem) return null;
      const height = getInitialItemCardHeight(kanbanDndItem);
      return <KanbanItem cardHeight={height} kanbanDndItemData={kanbanDndItem} style={style} />;
    },
    [fetchNextPage, getInitialItemCardHeight, getState, isFetchingNextPage, kanbanColumnId],
  );

  const getItemKey = useCallback(
    (index: number) => {
      const kanbanItems = kanbanColumnItemsSelector(getState(), kanbanColumnId);
      const kanbanItem = kanbanItems[index] || null;
      return kanbanItem?.sortableKanbanItemKey || String(index);
    },
    [getState, kanbanColumnId],
  );

  const getVirtualizedItemSize = useCallback(
    (index: number): number => {
      const kanbanItems = kanbanColumnItemsSelector(getState(), kanbanColumnId);
      const itemData = kanbanItems[index] || null;
      if (!itemData) return 0;

      const height = getInitialItemCardHeight(itemData);
      let addedHeightForBlueLine = 0;
      if (
        isDndSortableKanbanItemData(activeData) &&
        !!overData?.itemId &&
        overData.itemId === itemData.itemId &&
        activeData.itemId !== overData.itemId
      )
        addedHeightForBlueLine = KANBAN_ITEM_DROP_PLACEHOLDER_HEIGHT;

      return height + addedHeightForBlueLine + KANBAN_VIRTUALIZED_CARD_GUTTER;
    },
    [getInitialItemCardHeight, activeData, overData, getState, kanbanColumnId],
  );

  return { renderKanbanItem, getItemKey, getItemSize: getVirtualizedItemSize };
};
