import { ReactNode } from 'react';

import {
  KANBAN_COL_GUTTER,
  KANBAN_COL_LIST_SPACER,
  KANBAN_COL_WIDTH,
} from '~/components/KanbanView/shared/kanbanConstants';

interface KanbanFakeGroupProps {
  children: ReactNode;
}

const horizontalPadding = KANBAN_COL_LIST_SPACER + KANBAN_COL_GUTTER / 2;

export const KanbanFakeGroup = ({ children }: KanbanFakeGroupProps) => (
  <div
    className="grid h-full grid-cols-5 gap-[18px] overflow-hidden"
    style={{
      gridTemplateColumns: `repeat(5, ${KANBAN_COL_WIDTH}px)`,
      paddingLeft: horizontalPadding,
      paddingRight: horizontalPadding,
    }}
  >
    {children}
  </div>
);
