import { PublicClip } from '@air/api';
import { useAirModal } from '@air/provider-modal';
import { useShortIdContext } from '@air/provider-short-id';
import Router from 'next/router';
import { useCallback } from 'react';

import { useCopyToWorkspace } from '~/components/CopyToWorkspace/hooks/useCopyToWorkspace';
import { SharePublicClipModal } from '~/components/Modals/SharePublicClipModal';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { GetItemMenuOptionsCallback } from '~/constants/MenuOptions';
import { useDownloadPublicAsset } from '~/hooks/useDownloadPublicAsset';
import { useGetPublicCustomFieldsMenuOptions } from '~/hooks/useGetCustomFieldsMenuOptions';
import { useGetPublicClipMenuOptions } from '~/hooks/useGetPublicClipMenuOptions';
import { useGoToAssetPage } from '~/hooks/useGoToAssetPage';
import { getPublicClipInABoardShareUrl } from '~/swr-hooks/shortUrl/utils';
import { getBoardIdFromPath } from '~/utils/PathUtils';
import { canDownloadVersion, canExportAsset, canSeeAssetVersions } from '~/utils/permissions/assetPermissions';

export function useGetPublicAssetMenuOptionsCallback() {
  const { shortId } = useShortIdContext();
  const { downloadPublicAsset } = useDownloadPublicAsset();
  const [showSharePublicClipModal] = useAirModal(SharePublicClipModal);
  const { copyToAir } = useCopyToWorkspace();
  const { goToAssetPage } = useGoToAssetPage();
  const { permissions } = usePublicPermissions();

  const { getCustomFieldsMenuOptions } = useGetPublicCustomFieldsMenuOptions();
  const { getClipMenuOptions } = useGetPublicClipMenuOptions();

  const getAssetMenuOptionsCallback: GetItemMenuOptionsCallback<PublicClip> = useCallback(
    ({ item: clip }) =>
      ({ trackLocation }) => {
        const boardId = getBoardIdFromPath(window.location.pathname);

        const canViewAssetVersions = canSeeAssetVersions(permissions);
        const canDownload = canDownloadVersion(permissions);
        const canCopyToWorkspace = canExportAsset(permissions);

        return getClipMenuOptions({
          customFieldsMenuOptions: getCustomFieldsMenuOptions({ assets: [clip] }),
          onCopyToAir: canCopyToWorkspace
            ? () => {
                /**
                 * We only allow the entire asset to be copied if canViewAssetVerions
                 * is true. Else, we just let them copy that single clip (asset version)
                 */
                if (canViewAssetVersions) {
                  copyToAir({
                    assetId: clip.assetId,
                    trackLocation,
                  });
                } else {
                  copyToAir({
                    clipId: clip.id,
                    trackLocation,
                  });
                }
              }
            : undefined,
          onDownload: canDownload
            ? () => {
                downloadPublicAsset({
                  clipId: clip.id,
                  shortId,
                  trackLocation,
                });
              }
            : undefined,
          onOpen: () =>
            Router.ready(() => {
              goToAssetPage({ asset: clip, base: Router.asPath, trackLocation: 'open-dropdown-select' });
            }),
          onShare: () => {
            if (!boardId) return;
            showSharePublicClipModal({
              id: clip.id,
              url: getPublicClipInABoardShareUrl(shortId, clip.id, boardId),
              trackLocation,
            });
          },
        });
      },
    [
      permissions,
      getClipMenuOptions,
      getCustomFieldsMenuOptions,
      copyToAir,
      downloadPublicAsset,
      shortId,
      goToAssetPage,
      showSharePublicClipModal,
    ],
  );

  return {
    getAssetMenuOptionsCallback,
  };
}
