import { Eye, EyeClosed } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';
import { memo, useCallback } from 'react';

import { useIsFlattenedView } from '~/hooks/search/useIsFlattenedView';

export const MobileToggleNestedItemsButton = memo(() => {
  const { setIsFlattenedView, isFlattenedView } = useIsFlattenedView();

  const onClick = useCallback(() => {
    setIsFlattenedView(!isFlattenedView);
  }, [isFlattenedView, setIsFlattenedView]);

  const label = isFlattenedView ? 'Collapse nested items' : 'Expand nested items';

  return (
    <Tooltip label={label}>
      <IconButton
        appearance="ghost"
        color={isFlattenedView ? 'blue' : 'grey'}
        data-testid="MOBILE_TOGGLE_NESTED_ITEMS_BUTTON"
        icon={isFlattenedView ? EyeClosed : Eye}
        label={label}
        onClick={onClick}
        size="medium"
      />
    </Tooltip>
  );
});

MobileToggleNestedItemsButton.displayName = 'MobileToggleNestedItemsButton';
