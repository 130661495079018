import { Board } from '@air/api/types';
import { useBreakpointsContext } from '@air/provider-media-query';
import { noop } from 'lodash';
import { useMemo } from 'react';

import { BoardAncestorsDropdown } from '~/components/Workspace/BoardHeader/BoardCrumbs/BoardAncestorsDropdown';
import { ShortBoardCrumbs } from '~/components/Workspace/BoardHeader/BoardCrumbs/ShortBoardCrumbs';
import { BOARD_CRUMBS } from '~/constants/testIDs';

export interface BoardCrumbsProps {
  ancestors: Pick<Board, 'id' | 'title'>[];
  title: Board['title'];
  onBoardClick: (board: Pick<Board, 'id' | 'title'>) => void;
}

const AncestorDivider = () => <span className="mx-1 text-12 text-grey-8">/</span>;

const AncestorItem = ({
  id,
  title,
  onClick,
}: Pick<Board, 'id' | 'title'> & { onClick: (board: Pick<Board, 'id' | 'title'>) => void }) => (
  <div className="flex items-center" key={id}>
    <button className="text-12 text-grey-8 hover:underline" onClick={() => onClick({ id, title })}>
      <div className="max-w-[140px] truncate">{title}</div>
    </button>
    <AncestorDivider />
  </div>
);

/**
 * @deprecated Use `@air/component-breadcrumbs` instead.
 */
const BoardCrumbs = ({ ancestors = [], title, onBoardClick = noop }: BoardCrumbsProps) => {
  const { isAboveSmallScreen } = useBreakpointsContext();

  const ancestorsInDropdown = useMemo(() => ancestors.slice(1, ancestors.length - 1), [ancestors]);

  if (ancestors.length === 0) {
    return null;
  }

  const breadcrumbs = (
    <div className="flex items-center font-medium">
      <div className="flex items-center">
        <AncestorItem {...ancestors[0]} onClick={onBoardClick} />
        {ancestors.length > 2 && (
          <>
            <div className="flex items-center" key="ellipsis">
              <BoardAncestorsDropdown ancestors={ancestorsInDropdown} onBoardClick={onBoardClick} />
            </div>
            <AncestorDivider />
          </>
        )}
        {ancestors.length > 1 && <AncestorItem {...ancestors[ancestors.length - 1]} onClick={onBoardClick} />}
      </div>

      <span className="mr-20 truncate text-12 text-grey-10">{title}</span>
    </div>
  );

  return (
    <div className="flex h-8 w-full flex-1 items-center bg-grey-1 text-14" data-testid={BOARD_CRUMBS}>
      {!isAboveSmallScreen ? (
        <div className="py-2">
          <ShortBoardCrumbs ancestors={ancestors} onBoardClick={onBoardClick} title={title} />
        </div>
      ) : (
        <div className="flex w-full items-center">{breadcrumbs}</div>
      )}
    </div>
  );
};

export default BoardCrumbs;
