import { BaseCustomField, CustomFieldValue } from '@air/api/types';
import { memo } from 'react';

import { useKanbanContext } from '~/components/KanbanView/Providers/KanbanProvider';
import { currentKanbanGroupByCustomFieldIdSelector } from '~/store/configViews/selectors';
import { useAirSelector } from '~/utils/ReduxUtils';

import { DndKanbanManager } from './DndKanbanManager/DndKanbanManager';
import { KanbanColumnsManager } from './KanbanColumnsManager/KanbanColumnsManager';
interface KanbanColumnsManagerPropss {
  loadingState: JSX.Element;
  baseCustomField: BaseCustomField | undefined;
  customFieldValues: CustomFieldValue[] | undefined;
}

export const KanbanColumnsManagers = memo(({ loadingState, baseCustomField }: KanbanColumnsManagerPropss) => {
  const customFieldId = useAirSelector(currentKanbanGroupByCustomFieldIdSelector);
  const { customFieldValues } = useKanbanContext();

  // loadingState is passed in to prevent flicker
  if (!customFieldValues || !customFieldId || !baseCustomField) return loadingState;

  return (
    <>
      {/* key property allows react to reload the manager for different custom field ids */}
      <KanbanColumnsManager key={customFieldId} />
      <DndKanbanManager baseCustomField={baseCustomField} />
    </>
  );
});

KanbanColumnsManagers.displayName = 'KanbanColumnsManagers';
