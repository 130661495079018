import {
  closestCenter,
  CollisionDetection,
  DndContext,
  DragStartEvent,
  Modifier,
  MouseSensor,
  PointerSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import { memo, ReactNode, useCallback, useState } from 'react';

import { useIsSearchActive } from '~/hooks/search/useIsSearchActive';
import { isSortFieldKanbanDefaultSelector } from '~/store/configViews/selectors';
import { DndData, isDndSortableKanbanColumnData, isDndSortableKanbanItemData } from '~/types/DndKit';
import { useAirStore } from '~/utils/ReduxUtils';

interface AirDndContextProps {
  children: ReactNode;
}
/**
 * This provides the context for our entire application.
 * It will handle any type of drops of draggable items over
 * any types of droppable zones.
 *
 * Nesting another DndContext will prevent any of the draggable
 * children of the nested context from being read by this one,
 * which should be the highest up.
 */
export const AirDndContext = memo(({ children }: AirDndContextProps) => {
  const { isSearchActive } = useIsSearchActive();
  const { getState } = useAirStore();
  // Need this because scrolling in virtualized list unmounts the item, making
  // the active data disappear from the inner-workings of DndContext
  // Submitted PR to fix this: https://github.com/clauderic/dnd-kit/pull/690
  const [activeData, setActiveData] = useState<DndData | undefined>();
  // This allows user to single-click on item to open modal / ellipsis menu / other actions
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(MouseSensor),
  );

  const onDragStart = useCallback(({ active }: DragStartEvent) => {
    setActiveData(active.data.current);
  }, []);

  const onDragCancel = useCallback(() => {
    setActiveData(undefined);
  }, []);

  const onDragEnd = useCallback(() => {
    setActiveData(undefined);
  }, []);

  const collisionDetection: CollisionDetection = useCallback(
    (args) => {
      if (isDndSortableKanbanColumnData(activeData)) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter((container) =>
            isDndSortableKanbanColumnData(container.data.current),
          ),
        });
      }
      const isSortFieldKanbanDefault = isSortFieldKanbanDefaultSelector(getState());
      if ((isSearchActive || !isSortFieldKanbanDefault) && isDndSortableKanbanItemData(activeData)) {
        return pointerWithin({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => !isDndSortableKanbanItemData(container.data.current),
          ),
        });
      }
      return pointerWithin(args);
    },
    [getState, activeData, isSearchActive],
  );

  const horizontalColumnMovementModifier: Modifier = useCallback(
    (args) => {
      if (isDndSortableKanbanColumnData(activeData)) {
        const transform = {
          ...args.transform,
          y: 0, // keep column fixed to top
        };
        return restrictToFirstScrollableAncestor({ ...args, transform });
      }
      return args.transform;
    },
    [activeData],
  );

  return (
    <DndContext
      modifiers={[horizontalColumnMovementModifier]}
      sensors={sensors}
      collisionDetection={collisionDetection}
      onDragStart={onDragStart}
      onDragCancel={onDragCancel}
      onDragEnd={onDragEnd}
    >
      {children}
    </DndContext>
  );
});

AirDndContext.displayName = 'AirDndContext';
