import {
  AirActionTrackingLocation,
  useTrackCopiedPublicAsset,
  useTrackCopiedPublicBoard,
  useTrackCopiedPublicClip,
} from '@air/analytics';
import { Workspaces } from '@air/api';
import { useShortIdContext } from '@air/provider-short-id';
import { useIsLoggedIn } from '@air/utils-auth';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { useCreateCopyToWorkspaceTask } from '~/components/CopyToWorkspace/hooks/useCreateCopyToWorkspaceTask';
import { WorkspaceSelectModal } from '~/components/WorkspaceSelectModal/WorkspaceSelectModal';
import { useAccountContext } from '~/providers/AccountProvider';
import { useCreateWorkspace } from '~/swr-hooks/workspaces/useCreateWorkspace';
import { getWorkspacesListKey } from '~/swr-hooks/workspaces/useWorkspaces';

type BaseCopyToWorkspaceModalProps = {
  trackLocation: AirActionTrackingLocation;
};

type CopyAssetToWorkspaceModalProps = BaseCopyToWorkspaceModalProps & {
  assetId: string;
};

type CopyClipToWorkspaceModalProps = BaseCopyToWorkspaceModalProps & {
  clipId: string;
};

type CopyBoardToWorkspaceModalProps = BaseCopyToWorkspaceModalProps & {
  boardId: string;
};

export type CopyToWorkspaceModalProps =
  | CopyAssetToWorkspaceModalProps
  | CopyClipToWorkspaceModalProps
  | CopyBoardToWorkspaceModalProps;

const isCopyingBoard = (props: CopyToWorkspaceModalProps): props is CopyBoardToWorkspaceModalProps =>
  !!(props as CopyBoardToWorkspaceModalProps).boardId;

const isCopyingAsset = (props: CopyToWorkspaceModalProps): props is CopyAssetToWorkspaceModalProps =>
  !!(props as CopyAssetToWorkspaceModalProps).assetId;

const isCopyingClip = (props: CopyToWorkspaceModalProps): props is CopyClipToWorkspaceModalProps =>
  !!(props as CopyClipToWorkspaceModalProps).clipId;

export const CopyToWorkspaceModal = ({ onClose, ...props }: AirModalProps<CopyToWorkspaceModalProps>) => {
  const { trackLocation } = props;
  const { shortId } = useShortIdContext();
  const [isWorkspaceCreating, setIsWorkspaceCreating] = useState(false);
  const [isWorkspaceCreated, setIsWorkspaceCreated] = useState(false);

  const { data: account } = useAccountContext();
  const { isLoggedIn } = useIsLoggedIn();

  const permissions = isCopyingBoard(props)
    ? {
        board: {
          canCreate: true,
        },
      }
    : {
        asset: {
          canCreate: true,
        },
      };

  const {
    data: workspaces,
    isLoading,
    isRefetching: isValidating,
  } = useQuery({
    queryKey: getWorkspacesListKey({ permissions }),
    queryFn: () => Workspaces.list({ permissions }),
    enabled: isLoggedIn,
  });

  const { createWorkspace } = useCreateWorkspace();
  const { trackCopiedPublicAsset } = useTrackCopiedPublicAsset();
  const { trackCopiedPublicBoard } = useTrackCopiedPublicBoard();
  const { trackCopiedPublicClip } = useTrackCopiedPublicClip();
  const { createCopyToWorkspaceTask } = useCreateCopyToWorkspaceTask();

  useEffect(() => {
    if (
      !isWorkspaceCreating &&
      !isWorkspaceCreated &&
      account &&
      !isValidating &&
      !isLoading &&
      workspaces &&
      workspaces.length === 0
    ) {
      setIsWorkspaceCreating(true);
      const workspaceName = account.firstName ? `${account.firstName}'s workspace` : 'My workspace';
      createWorkspace(workspaceName, account.email).finally(() => {
        setIsWorkspaceCreating(false);
        setIsWorkspaceCreated(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces, isValidating, isLoading]);

  return (
    <WorkspaceSelectModal
      onClose={onClose}
      description="You will be redirected to this workspace after your copy is complete"
      workspaces={workspaces || []}
      isWorkspacesLoading={isLoading || isValidating || isWorkspaceCreating}
      onSave={(selectedWorkspace) => {
        createCopyToWorkspaceTask({
          shortId,
          targetWorkspaceName: selectedWorkspace.name,
          targetWorkspaceId: selectedWorkspace.id,
          sourceBoardIds: isCopyingBoard(props) ? [props.boardId] : undefined,
          sourceAssetIds: isCopyingAsset(props) ? [props.assetId] : undefined,
          sourceClipIds: isCopyingClip(props) ? [props.clipId] : undefined,
        });

        if (isCopyingBoard(props)) {
          trackCopiedPublicBoard({
            id: props.boardId,
            location: trackLocation,
            workspaceId: selectedWorkspace.id,
          });
        } else if (isCopyingAsset(props)) {
          trackCopiedPublicAsset({
            id: props.assetId,
            location: trackLocation,
            workspaceId: selectedWorkspace.id,
          });
        } else {
          trackCopiedPublicClip({
            clipId: props.clipId,
            location: trackLocation,
            workspaceId: selectedWorkspace.id,
          });
        }

        onClose();
      }}
    />
  );
};
