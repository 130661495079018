import { GetByShortIDBoardResponse, Permissions, ScopedPermissions, ShortUrl } from '@air/api';
import { Board, Clip, ShortUrlObjectType } from '@air/api/types';

export const getPublicClipShareUrl = (clipShortId: string) => `${window.location.origin}/a/${clipShortId}`;

export const getPublicClipInABoardShareUrl = (boardShortId: string, clipId: Clip['id'], boardId: Board['id']) =>
  `${window.location.origin}/a/${boardShortId}/b/${boardId}/c/${clipId}`;

export const getPublicBoardShareUrl = (shortId: string) => `${window.location.origin}/a/${shortId}`;

export const getPublicSubboardShareUrl = (parentShortId: string, boardId: string) =>
  `${window.location.origin}/a/${parentShortId}/b/${boardId}`;

export const getSeoTitle = (fallback: string, ...args: (string | undefined | null)[]) =>
  args.filter(Boolean).join(' / ') || fallback;

export async function fetchShortBoardData(
  shortId: string,
  boardId: string,
): Promise<{
  board: GetByShortIDBoardResponse['data']['board'];
  workspace: GetByShortIDBoardResponse['data']['workspace'];
  permissions: ScopedPermissions;
  requireAccount: boolean;
}> {
  const [getByShortIdResponse, descendentBoardResponse] = await Promise.all([
    ShortUrl.getByShortId(shortId),
    /**
     * We fetch for descendent board because `:boardId` in `/a/:shortId/b/:boardId` could either be the root board or
     * a sub-board
     */
    ShortUrl.getDescendantBoard(shortId, boardId),
  ]);

  /**
   * If short url is protected by password, this request will fail with "No permission" error
   * That's why it has to be fetched after short id details (if it is protected by password, it will throw an error and this line will not execute)
   */
  const permissions = await Permissions.getFromObjects({ shortId, objects: { boardIds: [boardId] } });

  if (getByShortIdResponse.type !== ShortUrlObjectType.board) {
    throw new Error(`Didn't fetch a board from ShortUrl.getByShortId`);
  }

  return {
    board: {
      ...descendentBoardResponse.board,
      ...getByShortIdResponse.data.board,
    },
    requireAccount: getByShortIdResponse.requireAccount,
    workspace: getByShortIdResponse.data.workspace,
    permissions,
  };
}
