import classNames from 'classnames';
import { HTMLAttributes, memo } from 'react';

import { PAGE_CONTENT_HEADER_MAX_WIDTH } from '~/components/PageHeader/constants';

export const ContentHeaderDescriptionContainer = memo(
  ({ className, style, ...props }: HTMLAttributes<HTMLDivElement>) => {
    return (
      <div
        className={classNames('min-w-[240px]', className)}
        style={{
          maxWidth: PAGE_CONTENT_HEADER_MAX_WIDTH,
          ...style,
        }}
        {...props}
      />
    );
  },
);

ContentHeaderDescriptionContainer.displayName = 'ContentHeaderDescriptionContainer';
