import { VisibleColumnType } from '@air/api/types';
import { useBreakpointsContext } from '@air/provider-media-query';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { CSSProperties, memo, useCallback, useMemo } from 'react';
import { ListChildComponentProps } from 'react-window';

import { KanbanFetchPageProvider } from '~/components/KanbanView/KanbanColumnsManagers/shared/KanbanFetchPageContext';
import { KANBAN_COL_LIST_SPACER, KANBAN_VIRTUALIZED_COL_WIDTH } from '~/components/KanbanView/shared/kanbanConstants';
import { visibleColumnIdsSelector } from '~/store/configViews/selectors';
import { kanbanColumnNeedsToLoadItemsSelector } from '~/store/kanbanManager/selectors';
import { DndItemType, DndSortableKanbanColumnData, isDndSortableKanbanColumnData } from '~/types/DndKit';
import { getSortableKanbanColumnKey } from '~/utils/getDndKeys';
import { useAirSelector, useAirStore } from '~/utils/ReduxUtils';

import { DndSortableContextKanbanColumn } from './DndSortableContextKanbanColumn/DndSortableContextKanbanColumn';
import { KanbanColumnFetchSync } from './KanbanColumnFetchSync/KanbanColumnFetchSync';
import { KanbanColumnSpacingWrapper } from './KanbanColumnSpacingWrapper';

interface KanbanColumnProps extends Pick<ListChildComponentProps, 'style'> {
  kanbanColumnId: string;
}
const KanbanColumn = memo(({ kanbanColumnId, style: scrollStyle }: KanbanColumnProps) => {
  const sortableData: DndSortableKanbanColumnData = useMemo(
    () => ({
      dndType: DndItemType.kanbanSortableColumn,
      kanbanColumnId,
    }),
    [kanbanColumnId],
  );
  const { isAboveMediumScreen } = useBreakpointsContext();
  const dndDisabled = useMemo(() => {
    if (!isAboveMediumScreen) return true;
    return kanbanColumnId === VisibleColumnType.unassignedCustomFieldValue;
  }, [isAboveMediumScreen, kanbanColumnId]);

  const { active, over, attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: getSortableKanbanColumnKey(kanbanColumnId),
    disabled: dndDisabled,
    data: sortableData,
  });

  const transformDisabled = useMemo(() => {
    const activeData = active?.data.current;
    const overData = over?.data.current;
    if (
      isDndSortableKanbanColumnData(activeData) &&
      isDndSortableKanbanColumnData(overData) &&
      overData.kanbanColumnId === VisibleColumnType.unassignedCustomFieldValue
    )
      return true;
    return false;
  }, [active, over]);

  const style = useMemo<CSSProperties>(
    () => ({
      ...scrollStyle,
      transform: transformDisabled ? undefined : CSS.Translate.toString(transform),
      transition,
    }),
    [transformDisabled, scrollStyle, transform, transition],
  );

  return (
    <>
      <KanbanColumnSpacingWrapper ref={setNodeRef} {...attributes} style={style}>
        <DndSortableContextKanbanColumn
          kanbanColumnId={kanbanColumnId}
          dndListeners={dndDisabled ? undefined : listeners}
        />
      </KanbanColumnSpacingWrapper>
      <KanbanColumnFetchSync kanbanColumnId={kanbanColumnId} />
    </>
  );
});
KanbanColumn.displayName = 'KanbanColumn';
const KANBAN_SPACER_ID = 'KANBAN_SPACER_ID';

export const useRenderKanbanColumn = () => {
  const { getState } = useAirStore();
  const visibleColumnIds = useAirSelector(visibleColumnIdsSelector);
  const kanbanColumnIds = useMemo(
    () => (visibleColumnIds ? [KANBAN_SPACER_ID, ...visibleColumnIds, KANBAN_SPACER_ID] : []),
    [visibleColumnIds],
  );
  const renderKanbanColumn = useCallback(
    ({ index, style }: ListChildComponentProps) => {
      const id = kanbanColumnIds[index];
      if (id === KANBAN_SPACER_ID) {
        return <div className="w-[19px] bg-red-9" />;
      }
      return (
        <KanbanFetchPageProvider>
          <KanbanColumn kanbanColumnId={id} style={style} />
        </KanbanFetchPageProvider>
      );
    },
    [kanbanColumnIds],
  );
  const getColumnWidth = useCallback(
    (index: number) => {
      const id = kanbanColumnIds[index];
      if (id === KANBAN_SPACER_ID) return KANBAN_COL_LIST_SPACER;
      return KANBAN_VIRTUALIZED_COL_WIDTH;
    },
    [kanbanColumnIds],
  );
  const colNeedsInitialItems = useCallback(
    (id: string) => {
      if (id === KANBAN_SPACER_ID) return false;
      return kanbanColumnNeedsToLoadItemsSelector(getState(), id);
    },
    [getState],
  );

  const isColumnLoaded = useCallback(
    (index: number) => {
      const id = kanbanColumnIds[index];
      if (id === KANBAN_SPACER_ID) return index !== 0;
      return !colNeedsInitialItems(id);
    },
    [colNeedsInitialItems, kanbanColumnIds],
  );
  return { renderKanbanColumn, getColumnWidth, kanbanColumnIds, colNeedsInitialItems, isColumnLoaded };
};
