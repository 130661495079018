import { CustomFieldValue } from '@air/api/types';
import { reportErrorToBugsnag } from '@air/utils-error';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { isDevOrTestStage } from '~/swr-hooks/utils';
import { UpdateItemsCustomFieldValueParams } from '~/utils/CustomFields/useUpdateItemsCustomFieldValue';

export interface KanbanProviderProps extends PropsWithChildren<object> {
  customFieldValues: CustomFieldValue[] | undefined;
  onUpdateItems: (params: Omit<UpdateItemsCustomFieldValueParams, 'workspaceId' | 'trackingLocation'>) => Promise<void>;
  workspaceId: string | undefined;
}

export type KanbanProviderContextValue = KanbanProviderProps;

const defaultValue: KanbanProviderContextValue = {
  customFieldValues: undefined,
  onUpdateItems: Promise.resolve,
  workspaceId: undefined,
};

const KanbanProviderContext = createContext<KanbanProviderContextValue>(defaultValue);

export const KanbanProvider = ({ children, customFieldValues, onUpdateItems, workspaceId }: KanbanProviderProps) => {
  const value = useMemo(
    () => ({ customFieldValues, onUpdateItems, workspaceId }),
    [customFieldValues, onUpdateItems, workspaceId],
  );

  return <KanbanProviderContext.Provider value={value}>{children}</KanbanProviderContext.Provider>;
};

export const useKanbanContext = () => {
  const context = useContext(KanbanProviderContext);

  if (context === defaultValue) {
    const error = 'KanbanProviderContext used outside of KanbanProvider';

    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
};
