import { PublicBoard } from '@air/api/types';
import { useShortIdContext } from '@air/provider-short-id';
import { useQueryClient } from '@tanstack/react-query';
import Router from 'next/router';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { getPublicBoardPageKey } from '~/components/Layouts/PublicBoardLayout/hooks/usePublicBoardPage';
import { createShortUrlBoardRoute } from '~/constants/routes';
import { setCentralizedBoardIdAndTitleAction, setCentralizedPublicBoardAction } from '~/store/centralizedBoard/actions';
import { LocationBoard } from '~/store/router/types';

export const useGoToPublicBoard = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { shortId } = useShortIdContext();

  const goToPublicBoard = useCallback(
    async (board: LocationBoard, _location?: string) => {
      await Router.push(createShortUrlBoardRoute(shortId, board.id), undefined, { shallow: true });

      const cachedBoard = queryClient.getQueryData<PublicBoard>(
        getPublicBoardPageKey({
          shortId,
          boardId: board.id,
        }),
      );

      if (cachedBoard) {
        dispatch(setCentralizedPublicBoardAction({ board: cachedBoard }));
      } else {
        dispatch(setCentralizedBoardIdAndTitleAction({ board }));
      }
    },
    [dispatch, queryClient, shortId],
  );

  return {
    goToPublicBoard,
  };
};
