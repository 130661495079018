import { VisibleColumnType } from '@air/api/types';

export const getColumnFiltersForGroupedSearch = ({
  customFieldId,
  visibleColumnId,
}: {
  customFieldId: string;
  visibleColumnId: string;
}) => ({
  customFields: [
    {
      id: customFieldId,
      filter:
        visibleColumnId === VisibleColumnType.unassignedCustomFieldValue
          ? {
              notSet: true,
            }
          : {
              is: visibleColumnId,
            },
    },
  ],
});
