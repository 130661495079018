import { useDndContext } from '@dnd-kit/core';
import { noop } from 'lodash';
import { memo, useEffect, useRef } from 'react';
import { AutoSizer } from 'react-virtualized';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { currentKanbanGroupByCustomFieldIdSelector } from '~/store/configViews/selectors';
import { useAirSelector } from '~/utils/ReduxUtils';

import { useRenderKanbanColumn } from './useRenderKanbanColumn';

interface KanbanHorizontalColumnsScrollerProps {
  loadNextColumnGroup?: (startIndex: number, stopIndex: number) => Promise<void>;
}

export const KanbanHorizontalColumnsScroller = memo(({ loadNextColumnGroup }: KanbanHorizontalColumnsScrollerProps) => {
  const customFieldId = useAirSelector(currentKanbanGroupByCustomFieldIdSelector);
  const listRef = useRef<VariableSizeList | null>(null);
  const { isColumnLoaded, kanbanColumnIds, renderKanbanColumn, getColumnWidth } = useRenderKanbanColumn();
  const { over } = useDndContext();

  useEffect(() => {
    // redraw widths of columns
    listRef?.current?.resetAfterIndex(0, false);
  }, [over, kanbanColumnIds, customFieldId]);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <InfiniteLoader
          isItemLoaded={isColumnLoaded}
          itemCount={kanbanColumnIds.length}
          loadMoreItems={loadNextColumnGroup || noop}
          threshold={0}
          minimumBatchSize={1}
        >
          {({ onItemsRendered, ref }) => (
            <VariableSizeList
              className="no-scrollbar"
              height={height}
              width={width}
              itemCount={kanbanColumnIds.length}
              itemSize={getColumnWidth}
              layout="horizontal"
              onItemsRendered={onItemsRendered}
              ref={(el: VariableSizeList | null) => {
                listRef.current = el;
                // need to use their ref because it is used under the hood
                // @ts-ignore their ref is `setRef(el: any) => void`
                ref(el);
              }}
            >
              {renderKanbanColumn}
            </VariableSizeList>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
});

KanbanHorizontalColumnsScroller.displayName = 'KanbanHorizontalColumnsScroller';
