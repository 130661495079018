import { ClipAndBoardListOptions } from '@air/api/types';
import { resetSelectedItemsAction } from '@air/redux-selected-items';
import { horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useDispatch } from 'react-redux';
import { usePrevious } from 'react-use';
import { useDeepCompareMemo } from 'use-deep-compare';

import { useKanbanContext } from '~/components/KanbanView/Providers/KanbanProvider';
import {
  defaultKanbanSort,
  KANBAN_GROUP_FETCH_SIZE,
  KANBAN_VIRTUALIZED_COL_WIDTH,
} from '~/components/KanbanView/shared/kanbanConstants';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { currentSortFieldSelector, visibleColumnIdsSelector } from '~/store/configViews/selectors';
import { resetKanbanManagerAction } from '~/store/kanbanManager/actions';
import { useBoardSearchParams } from '~/swr-hooks/search/useBoardSearchParams';
import { useClipSearchParams } from '~/swr-hooks/search/useClipSearchParams';
import { getSortableKanbanColumnKey, sortableContextKanbanColumnOrderKey } from '~/utils/getDndKeys';
import { useAirSelector, useAirStore } from '~/utils/ReduxUtils';

import { KanbanHorizontalColumnsScroller } from './KanbanHorizontalColumnsScroller/KanbanHorizontalColumnsScroller';
import { useRenderKanbanColumn } from './KanbanHorizontalColumnsScroller/useRenderKanbanColumn';
import { useLoadKanbanColumnGroup } from './useLoadKanbanColumnGroup/useLoadKanbanColumnGroup';

export const KanbanColumnsManager = memo(() => {
  const { customFieldValues } = useKanbanContext();
  const [kanbanAreaRef, setKanbanAreaRef] = useState<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const visibleColumnIds = useAirSelector(visibleColumnIdsSelector);
  const boardId = useURLBoardIdSelector();
  const prevBoardId = usePrevious(boardId);
  const { getState } = useAirStore();

  const isChangingBoards = !isEqual(prevBoardId, boardId);
  const [isLoadingColumnGroup, setIsLoadingColumnGroup] = useState(true);
  const areaWidth = useMemo(() => kanbanAreaRef?.clientWidth, [kanbanAreaRef?.clientWidth]);
  const { loadColumnGroup } = useLoadKanbanColumnGroup();
  const clipsFetchParams = useClipSearchParams();
  const boardsFetchParams = useBoardSearchParams();
  const sortField = useAirSelector(currentSortFieldSelector);

  const searchParams = useDeepCompareMemo<Partial<ClipAndBoardListOptions>>(
    () => ({
      ...clipsFetchParams,
      ...boardsFetchParams,
      sortBy: undefined, // ensure we use the new sort fieldName and direction
      sortField: sortField || defaultKanbanSort,
    }),
    [sortField, clipsFetchParams, boardsFetchParams],
  );

  const groupFetchSizeFromAllowedWidth: number = useMemo(() => {
    if (!areaWidth) return KANBAN_GROUP_FETCH_SIZE;
    return Math.max(KANBAN_GROUP_FETCH_SIZE, Math.ceil(areaWidth / KANBAN_VIRTUALIZED_COL_WIDTH) + 1);
  }, [areaWidth]);
  const { kanbanColumnIds, colNeedsInitialItems } = useRenderKanbanColumn();

  const hasMoreToLoad = useMemo(() => {
    // some column exists that is not in initialKanbanItems
    return kanbanColumnIds.some(colNeedsInitialItems);
  }, [colNeedsInitialItems, kanbanColumnIds]);

  const loadNextColumnGroup = useCallback(
    async (passedStartIndex = 0) => {
      const startIndex = passedStartIndex - 1 < 0 ? 0 : passedStartIndex - 1; // just make sure to get one overlap, just in case.
      const stopIndex = startIndex + groupFetchSizeFromAllowedWidth;
      // ensure we have the latest visible columns. This was failing when referencing the visibleColumnIds
      // because after groupBy is set on configurable views, we separately update the visible columns after fetching the custom field values
      const updatedColumnIds = visibleColumnIdsSelector(getState());
      if (!areaWidth || !hasMoreToLoad || !updatedColumnIds || isLoadingColumnGroup) return;
      setIsLoadingColumnGroup(true);
      await loadColumnGroup({ searchParams, groupOfVisibleColumnIds: updatedColumnIds.slice(startIndex, stopIndex) });
      setIsLoadingColumnGroup(false);
    },
    [
      searchParams,
      areaWidth,
      isLoadingColumnGroup,
      getState,
      loadColumnGroup,
      hasMoreToLoad,
      groupFetchSizeFromAllowedWidth,
    ],
  );
  const loadGroup = useCallback(async () => {
    // ensure we have the latest visible columns. This was failing when referencing the visibleColumnIds
    // because after groupBy is set on configurable views, we separately update the visible columns after fetching the custom field values
    const updatedColumnIds = visibleColumnIdsSelector(getState());
    if (!updatedColumnIds || !areaWidth) return;
    setIsLoadingColumnGroup(true);
    await loadColumnGroup({
      searchParams,
      groupOfVisibleColumnIds: updatedColumnIds.slice(0, groupFetchSizeFromAllowedWidth),
    });
    setIsLoadingColumnGroup(false);
  }, [searchParams, areaWidth, getState, loadColumnGroup, groupFetchSizeFromAllowedWidth]);

  useEffect(() => {
    // wait until we know the screen size to load the first time
    if (areaWidth && customFieldValues) loadGroup();
  }, [areaWidth, customFieldValues, loadGroup]);

  useEffect(() => {
    // when visibleColumIds change, trigger a refetch
    loadGroup();
  }, [visibleColumnIds, loadGroup]);

  useEffect(() => {
    if (isChangingBoards) {
      //trigger re-fetch of data to account for changes made in other views
      dispatch(resetKanbanManagerAction());
      dispatch(resetSelectedItemsAction());
      loadGroup();
    }
  }, [loadGroup, dispatch, isChangingBoards]);

  const sortableColumnIds = useMemo(() => {
    if (!visibleColumnIds) return [];
    return visibleColumnIds.map((id) => getSortableKanbanColumnKey(id));
  }, [visibleColumnIds]);
  return (
    <div className="h-full" ref={setKanbanAreaRef}>
      <SortableContext
        items={sortableColumnIds}
        id={sortableContextKanbanColumnOrderKey}
        strategy={horizontalListSortingStrategy}
      >
        <KanbanHorizontalColumnsScroller loadNextColumnGroup={loadNextColumnGroup} />
      </SortableContext>
    </div>
  );
});

KanbanColumnsManager.displayName = 'KanbanColumnsManager';
